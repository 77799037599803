import { useEffect, useState, ChangeEvent } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../api/axiosInstance";
import { useSnackbar } from "notistack";
import { Student } from "../../interfaces/student";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../styles/theme";
import EditStudentTable from "./EditStudentTable";
import EditStudentDocuments from "./EditStudentDocuments";
import EditStudentPayments from "./StudentPayments/EditStudentPayments";
import ReusableTabbedContainer from "../../components/Layouts/TabbedContainer/Containers/GeneralContainer";

const studentDocs = process.env.REACT_APP_GET_STUDENT_DOCUMENTS;

function EditStudent() {
  const { id: studentId } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [student, setStudent] = useState<Student>({
    first_name: "",
    last_name: "",
    birthdate: "",
    weight: "",
    school: "",
    school_grade: "",
    school_level: "",
    address: "",
    category: {
      id: "",
      title: "",
      min_weight: "",
      max_weight: "",
    },
  });
  const [studentPhotoPath, setStudentPhotoPath] = useState<string | null>(null);

  const [activeTab, setActiveTab] = useState<
    "table" | "documents" | "payments"
  >("table");
  const params = useParams();

  console.log(params);
  console.log(studentId);

  const studentReq = process.env.REACT_APP_STUDENT;

  useEffect(() => {
    const fetchStudent = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get(`${studentReq}/${studentId}`);
        console.log("Students's response data:", response.data.data.student);
        setStudent(response.data.data.student);

        const docsResponse = await axiosInstance.get(
          `${studentDocs}/${studentId}`
        );
        const photoDocument = docsResponse.data.data.documents.find(
          (doc: any) => doc.document_id === 8
        );

        if (photoDocument) {
          setStudentPhotoPath(photoDocument.file_path);
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    fetchStudent();
  }, [studentId]);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    setStudent((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      console.log("Student data sent:", student);
      const response = await axiosInstance.put(
        `${studentReq}/${studentId}`,
        student
      );
      console.log(response.data);
      enqueueSnackbar("Alumno editado exitosamente", { variant: "success" });
    } catch (err) {
      console.log(err);
      enqueueSnackbar("hubo un error al editar el alumno", {
        variant: "error",
      });
    }
    setIsLoading(false);
  };

  const tabs = [
    { label: "Jugador", value: "table" },
    { label: "Documentos", value: "documents" },
    { label: "Pagos", value: "payments" },
  ];

  return (
    <ThemeProvider theme={theme}>
      <ReusableTabbedContainer
        activeTab={activeTab}
        onTabClick={(tab) =>
          setActiveTab(tab as "table" | "documents" | "payments")
        }
        tabs={tabs}
      >
        {activeTab === "table" && (
          <EditStudentTable
            student={student}
            setStudent={setStudent}
            handleChange={handleChange}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            studentPhotoPath={studentPhotoPath}
          />
        )}
        {activeTab === "documents" && <EditStudentDocuments />}
        {activeTab === "payments" && <EditStudentPayments />}
      </ReusableTabbedContainer>
    </ThemeProvider>
  );
}

export default EditStudent;
