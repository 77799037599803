import axios from "axios";
import { useState } from "react";
import { Box, Button, Container, Link, Paper, Typography } from "@mui/material";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../styles/theme";
import SignUpForm from "./SingUpForm";
import Copyright from "../../components/Copyright/Copyright";
import Image from "../../components/Image/Image";
import Logo from "../../assets/redsLogo.png";

function SignUp() {
  const { enqueueSnackbar } = useSnackbar();
  const [signUpData, setSignUpData] = useState({
    email: "",
    password: "",
    passConfirmation: "",
  });

  const redSignUp = process.env.REACT_APP_SIGN_UP;

  function handleSignUpError(error: any) {
    let errorMessage = "Ocurrió un error al intentar registrarse";
    if (error.response) {
      const { data, status } = error.response;

      if (status === 409) {
        errorMessage = data.message || "El usuario ya existe";
      } else if (status === 422) {
        errorMessage = "Por favor verifica los datos ingresados";
      } else if (status === 500) {
        errorMessage =
          "Ocurrió un error en el servidor, por favor intenta de nuevo más tarde";
      } else {
        errorMessage = data.message || "Ocurrió un error inesperado";
      }
    }
    enqueueSnackbar(errorMessage, { variant: "error" });
  }

  //Post Request
  const singUpRequest = async () => {
    const { email, password, passConfirmation } = signUpData;

    // Verificar si el correo y la contraseña están presentes
    if (!email || !password) {
      enqueueSnackbar(
        "Por favor proporciona un correo electrónico y una contraseña",
        { variant: "error" }
      );
      return false;
    }

    // Verificar si las contraseñas son iguales antes de enviar la solicitud
    if (password !== passConfirmation) {
      enqueueSnackbar("Las contraseñas son diferentes", { variant: "error" });
      return false;
    }

    const dataToSend = { email, password };

    // Verificar si las contraseñas son iguales antes de enviar la solicitud

    try {
      const response = await axios.post(`${redSignUp}`, dataToSend);
      console.log(dataToSend);
      console.log(response.data);
      return true;
    } catch (err) {
      handleSignUpError(err);
      console.log(dataToSend);
      console.log(err);
      return false;
    }
  };

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isSignUpSuccessful = await singUpRequest();
    if (isSignUpSuccessful) {
      navigate("/iniciar", { state: { fromRegistration: true } });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[50]
              : theme.palette.grey[900],
        }}
      >
        <Container component="main" maxWidth="xs">
          <Paper
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              p: 3,
            }}
          >
            <Box sx={{ width: 1 / 2 }}>
              <Image src={Logo} alt="Rojos Logo" />
            </Box>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <SignUpForm
                signUpData={signUpData}
                setSignUpData={setSignUpData}
              />
              <Typography variant="body2" color="text.secondary" mt={2}>
                Al crear una cuenta con nosotros estás de acuerdo con nuestros{" "}
                <Link>términos de uso</Link> y <Link>aviso de privacidad</Link>
              </Typography>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Registrarse
              </Button>
              <Link variant="body2" component={RouterLink} to="/iniciar">
                ¿Ya tienes una cuenta? Inicia sesión
              </Link>
            </Box>
          </Paper>
          <Copyright mt={8} mb={4} />
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default SignUp;
