import { Fragment, useState } from "react";
import { ListItemButton, ListItemText, List, Collapse } from "@mui/material";
import { Student } from "../../interfaces/student";
import UserDocumentSelect from "./PaymentSelect";

interface IUserAccountProps {
  students?: Student[];
}

function StudentList({ students }: IUserAccountProps) {
  const [selectedStudentId, setSelectedStudentId] = useState<string | null>(
    null
  );

  const handleStudentClick = (id: string) => {
    setSelectedStudentId((prevSelectedId) =>
      prevSelectedId === id ? null : id
    );
  };

  const renderStudents = () => {
    if (!students) {
      return <div>Cargando Estudiantes...</div>;
    }

    return students.map(({ id, first_name, last_name }) => (
      <Fragment key={id}>
        <ListItemButton
          selected={id === selectedStudentId}
          onClick={() => id && handleStudentClick(id)}
        >
          <ListItemText>{`${first_name} ${last_name}`}</ListItemText>
        </ListItemButton>
        <Collapse in={selectedStudentId === id} unmountOnExit>
          <UserDocumentSelect studentId={id!} /> {/* Afirmación de tipo aquí */}
        </Collapse>
      </Fragment>
    ));
  };

  return (
    <Fragment>
      <List>{renderStudents()}</List>
    </Fragment>
  );
}

export default StudentList;
