import { Grid } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import UsersList from "./UsersList";
import EditUser from "./EditUser";

function Users() {
  return (
    <Grid container item xs={12}>
      <Routes>
        <Route path="/" element={<UsersList />} />
        <Route path=":id/edit" element={<EditUser />} />
      </Routes>
    </Grid>
  );
}

export default Users;
