import React, { useState } from "react";
import ContentLayout from "../../components/Layouts/ContentLayout/ContentLayout";
import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  Divider,
} from "@mui/material";
import StudentForm from "../../components/Layouts/Forms/StudentForm";
import TutorForm from "../../components/Layouts/Forms/TutorForm";
import { useNavigate } from "react-router-dom";
import {
  useCreateStudent,
  useCreateTutor,
  useGetUserStudentnTutor,
} from "../../components/apiRequest/StudentAndTutorRequests";

function NewAccount() {
  const [step, setStep] = useState(0);

  const [studentData, setStudentData] = useState({
    first_name: "",
    last_name: "",
    birthdate: "",
    weight: "",
    school: "",
    school_grade: "",
    school_level: "",
    address: "",
  });
  const [tutorData, setTutorData] = useState({
    first_name: "",
    last_name: "",
    company: "",
    company_role: "",
    phone: "",
    email: "",
  });

  const navigate = useNavigate();

  const { students, tutors, isLoading, isError } = useGetUserStudentnTutor();

  const {
    loading: studentLoading,
    error: studentError,
    createStudent,
  } = useCreateStudent(studentData, students);

  const {
    loading: tutorLoading,
    error: tutorError,
    createTutor,
  } = useCreateTutor(tutorData, tutors);

  const handleCreateStudent = async () => {
    try {
      await createStudent(studentData, students);
      console.log("Estudiante creado con éxito");
      setStep(2);
    } catch (error) {
      console.error("Error al crear el estudiante:", error);
      if (studentError) {
        console.error("Detalle del error:", studentError);
      }
    }
  };

  const handleAddAnotherPlayer = () => {
    setStudentData({
      first_name: "",
      last_name: "",
      birthdate: "",
      weight: "",
      school: "",
      school_grade: "",
      school_level: "",
      address: "",
    });
    setStep(1);
  };

  const handleNoMorePlayers = () => {
    setStep(3);
  };

  const handleCreateTutor = async () => {
    try {
      await createTutor(tutorData, tutors);
      setStep(4);
    } catch (error) {
      console.error("Error al crear el tutor:", error);
    }
  };

  const handleGoToPayments = () => {
    navigate("/pagos");
  };

  return (
    <ContentLayout>
      {step === 0 && (
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} style={{ textAlign: "center", marginTop: "16px" }}>
            <Typography variant="h5">
              Gracias por registrarte en Rojos CDMX / San Luis Potosí, a
              continuación prepararemos tu cuenta.
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button onClick={() => setStep(1)}>Siguiente</Button>
          </Grid>
        </Grid>
      )}
      {step === 1 && (
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Crear Jugador
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ mb: 4 }} />
          </Grid>
          <Grid item xs={12}>
            <StudentForm
              studentData={studentData}
              setStudentData={setStudentData}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ mb: 4 }} />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={handleCreateStudent} disabled={studentLoading}>
              {studentLoading ? (
                <CircularProgress size={24} />
              ) : (
                "Crear Jugador"
              )}
              {studentError && <p>Error al crear el estudiante</p>}
            </Button>
          </Grid>
        </Grid>
      )}
      {step === 2 && (
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="h5">¿Desea agregar otro jugador?</Typography>
            <Button onClick={handleAddAnotherPlayer}>Sí</Button>
            <Button onClick={handleNoMorePlayers}>No</Button>
          </Grid>
        </Grid>
      )}

      {step === 3 && (
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Crear Tutor @
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ mb: 4 }} />
          </Grid>
          <Grid item xs={12}>
            <TutorForm tutorData={tutorData} setTutorData={setTutorData} />
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ mb: 4 }} />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={handleCreateTutor} disabled={tutorLoading}>
              {tutorLoading ? <CircularProgress size={24} /> : "Agregar Tutor"}
            </Button>
            {tutorError && <p>Error al crear el tutor</p>}
          </Grid>
        </Grid>
      )}
      {step === 4 && (
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} style={{ textAlign: "center", marginTop: "16px" }}>
            <Typography variant="h5">
              Se ha creado un tutor y un jugador correctamente, el siguiente
              paso será subir tu recibo de pago.
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button onClick={handleGoToPayments}>Ir a Pagos</Button>
          </Grid>
        </Grid>
      )}
    </ContentLayout>
  );
}

export default NewAccount;
