import React, { useState } from "react";
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Paper,
} from "@mui/material";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { Document } from "../../interfaces/document";

interface IDocumentListProps {
  documentList: Document[];
}

function DocumentsList({ documentList }: IDocumentListProps) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const renderDocuments = () => {
    return documentList.map(({ id, title }) => (
      <ListItem key={id}>
        <ListItemIcon>
          <HorizontalRuleIcon sx={{ color: "primary.main" }} />
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
    ));
  };

  return (
    <>
      <Button
        id="documents-list-button"
        variant="outlined"
        onClick={handleClick}
        endIcon={<ArrowDropDown />}
      >
        Documentos
      </Button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List>{renderDocuments()}</List>
      </Collapse>
    </>
  );
}

export default DocumentsList;
