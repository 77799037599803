import { Box, Container, Grid, TextField, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Leads } from "../../interfaces/lead";
import styled from "@emotion/styled";

const theme = createTheme({
  palette: {
    primary: {
      main: "#e30613",
    },
  },
});

const FullWidthDatePicker = styled(DatePicker)`
  width: 100%;
`;

interface ILeadProps {
  leadData: Leads;
  setLeadData: any;
}

function StudentForm(props: ILeadProps) {
  const { leadData, setLeadData } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              p: 3,
            }}
          >
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography align="left" sx={{ mb: 1 }}>
                    Introduza la información del jugador:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    id="first_name"
                    name="first_name"
                    label="Nombre"
                    required
                    fullWidth
                    value={leadData.first_name}
                    onChange={(e) =>
                      setLeadData({ ...leadData, first_name: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="last_name"
                    name="last_name"
                    label="Apellido"
                    required
                    fullWidth
                    value={leadData.last_name}
                    onChange={(e) =>
                      setLeadData({ ...leadData, last_name: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FullWidthDatePicker
                    label="Fecha de Nacimiento"
                    value={
                      leadData.birthdate ? new Date(leadData.birthdate) : null
                    }
                    onChange={(date) => {
                      if (date instanceof Date) {
                        const dateString = date.toISOString().substring(0, 10);
                        setLeadData({ ...leadData, birthdate: dateString });
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default StudentForm;
