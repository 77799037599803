import { Grid } from "@mui/material";
import TutorSelect from "./TutorSelect";
import StudentSelect from "./StudentSelect";

function UserSelect() {
  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <TutorSelect />
      </Grid>
      <Grid item xs={12}>
        <StudentSelect />
      </Grid>
    </Grid>
  );
}

export default UserSelect;
