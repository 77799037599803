import { Navigate, useLocation } from "react-router-dom";
import { useContext } from "react";
import UserContext from "../../context/UserContext";
import MainAdmin from "../../pages/Main/MainAdmin";
import Main from "../../pages/Main/Main";

function AdminOrUserMain() {
  const userContext = useContext(UserContext);
  const { user } = userContext;

  if (user?.is_admin) {
    return <MainAdmin />;
  } else {
    return <Main />;
  }
}

export default AdminOrUserMain;
