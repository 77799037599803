import { Grid, Typography } from "@mui/material";
import axiosInstance from "../../api/axiosInstance";
import { useEffect, useState } from "react";
import TutorList from "./TutorList";
import ContentLayout from "../../components/Layouts/ContentLayout/ContentLayout";

function TutorSelect() {
  const [tutors, setTutors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const getTutors = process.env.REACT_APP_GET_ALL_TUTORS;

  useEffect(() => {
    const fetchTutors = async () => {
      try {
        const response = await axiosInstance.get(`${getTutors}`);
        setTutors(response.data.data.tutors);
        setIsLoading(false);
        console.log(response.data);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An error occurred");
        }
        setIsLoading(false);
        console.log(err);
      }
    };
    fetchTutors();
  }, []);

  return (
    <ContentLayout>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Seleccione un Tutor
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <Typography variant="body1">Cargando...</Typography>
        ) : error ? (
          <Typography variant="body1">
            Error en la petición del servidor
          </Typography>
        ) : tutors.length === 0 ? (
          <Typography variant="body1">No hay información disponible</Typography>
        ) : (
          <TutorList tutors={tutors} />
        )}
      </Grid>
    </ContentLayout>
  );
}

export default TutorSelect;
