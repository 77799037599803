import React, { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import axiosInstance from "../../../api/axiosInstance";

interface IUploadedPhotoProps {
  userId: string;
}

function UploadedPhoto({ userId }: IUploadedPhotoProps) {
  const [photoPath, setPhotoPath] = useState<string | null>(null);
  const studentDocs = process.env.REACT_APP_GET_STUDENT_DOCUMENTS;

  useEffect(() => {
    const fetchPhotoPath = async () => {
      try {
        const response = await axiosInstance.get(`${studentDocs}/${userId}`);
        console.log("Documentos recuperados:", response.data.data.documents);
        const photoDocument = response.data.data.documents.find(
          (doc: any) => doc.document_id === 8
        );

        if (photoDocument) {
          setPhotoPath(photoDocument.file_path);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchPhotoPath();
  }, [userId]);

  if (!photoPath) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography>No existe ninguna foto aún.</Typography>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
    >
      <Box
        component="img"
        src={photoPath}
        alt="Foto del alumno"
        sx={{
          maxWidth: "75%",
          maxHeight: "400px",
          display: "block",
          margin: "0 auto",
          borderRadius: "8px", // Bordes redondeados
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Sombra alrededor de la imagen
          border: "1px solid #E0E0E0", // Borde sutil alrededor de la imagen
        }}
      />
    </Box>
  );
}

export default UploadedPhoto;
