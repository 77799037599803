import { Grid, TextField } from "@mui/material";
import { Tutor } from "../../../interfaces/tutor";

interface TutorFormProps {
  tutorData: Tutor;
  setTutorData: React.Dispatch<React.SetStateAction<Tutor>>;
  disabled?: boolean;
}

function TutorForm({ tutorData, setTutorData, disabled }: TutorFormProps) {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setTutorData((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <Grid container item spacing={2} xs={12} sx={{ mb: 4 }}>
      <Grid item xs={12} md={6}>
        <TextField
          label="Nombre"
          name="first_name"
          variant="filled"
          disabled={disabled}
          fullWidth
          value={tutorData.first_name}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Apellido"
          name="last_name"
          variant="filled"
          disabled={disabled}
          fullWidth
          value={tutorData.last_name}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Empresa donde trabaja"
          name="company"
          variant="filled"
          disabled={disabled}
          fullWidth
          value={tutorData.company}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Puesto que ocupa"
          name="company_role"
          variant="filled"
          disabled={disabled}
          fullWidth
          value={tutorData.company_role}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Teléfono"
          name="phone"
          variant="filled"
          disabled={disabled}
          fullWidth
          value={tutorData.phone}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Correo"
          name="email"
          variant="filled"
          disabled={disabled}
          fullWidth
          value={tutorData.email}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>
  );
}

export default TutorForm;
