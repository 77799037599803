import { useContext, useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { Box, Button, Container, Link, Paper, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../styles/theme";
import Copyright from "../components/Copyright/Copyright";
import Image from "../components/Image/Image";
import AuthContext from "../context/AuthContext";
import UserContext from "../context/UserContext";
import Logo from "../assets/redsLogo.png";

interface LocationState {
  from?: {
    pathname: string;
  };
}

function Login() {
  const { enqueueSnackbar } = useSnackbar();
  const authContext = useContext(AuthContext);
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const state: LocationState = location.state as LocationState;
  const from = state?.from?.pathname || "/";

  const [data, setData] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (authContext.isAuthenticated) navigate(from, { replace: true });
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const user = await authContext.login(data.email, data.password);
      userContext.setUser(user);
      console.log(data);
      authContext.setAuthenticated(true);
      if (location.state?.fromRegistration) {
        navigate("/nueva-cuenta");
      } else {
        navigate(from, { replace: true });
      }
    } catch (err) {
      if (err instanceof Error) {
        enqueueSnackbar(err.message, { variant: "error" });
        console.log(data);
      } else {
        enqueueSnackbar("Unknown Error", { variant: "error" });
        console.log(data);
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[50]
              : theme.palette.grey[900],
        }}
      >
        <Container component="main" maxWidth="xs">
          <Paper
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              p: 3,
            }}
          >
            <Box sx={{ width: 1 / 2 }}>
              <Image src={Logo} alt="Reds Logo" />
            </Box>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Correo Electrónico"
                name="email"
                autoComplete="email"
                autoFocus
                value={data.email}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
                value={data.password}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 1 }}
              >
                Iniciar Sesión
              </Button>
              <Button
                fullWidth
                variant="contained"
                sx={{ mb: 2 }}
                onClick={() => navigate("/registro")}
              >
                Registrarse
              </Button>

              <Link onClick={() => navigate("/recuperar")} variant="body2">
                ¿Olvidaste tu contraseña?
              </Link>
            </Box>
          </Paper>
          <Copyright mt={8} mb={4} />
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Login;
