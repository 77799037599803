import {
  alpha,
  Menu,
  MenuItem,
  useMediaQuery,
  Theme,
  Box,
  Button,
} from "@mui/material";
import { useState } from "react";
import theme from "../../../../styles/theme";
interface GeneralTabsProps {
  activeTab: string;
  onTabClick: (tab: string) => void;
  tabs: { label: string; value: string }[];
}

const GeneralTabs: React.FC<GeneralTabsProps> = ({
  activeTab,
  onTabClick,
  tabs,
}) => {
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        mb: 2,
        borderBottom: "1px solid #d3d3d3",
      }}
    >
      {isSm ? (
        <>
          <Button onClick={handleMenuClick}>
            {tabs.find((tab) => tab.value === activeTab)?.label || "Menú"}
          </Button>
          <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
          >
            {tabs.map((tab) => (
              <MenuItem
                key={tab.value}
                onClick={() => {
                  onTabClick(tab.value);
                  handleMenuClose();
                }}
                selected={activeTab === tab.value}
              >
                {tab.label}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        tabs.map((tab) => (
          <Button
            key={tab.value}
            onClick={() => onTabClick(tab.value)}
            sx={{
              p: 1,
              borderRadius: 0,
              backgroundColor:
                activeTab === tab.value ? alpha("#e30613", 0.2) : "transparent",
            }}
          >
            {tab.label}
          </Button>
        ))
      )}
    </Box>
  );
};

export default GeneralTabs;
