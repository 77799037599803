import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Button,
  Divider,
} from "@mui/material";
import { Student } from "../../../interfaces/student";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import StudentForm from "../../../components/Layouts/Forms/StudentForm";
import ConfirmDialog from "../../../components/Popups/ConfirmDialog";

interface StudentItemProps {
  studentData: Student;
  loading: boolean;
  onDelete: (id: string) => void;
  onSave: (id: string) => void;
  setStudentData: (value: React.SetStateAction<Student>) => void;
}

const StudentItem: React.FC<StudentItemProps> = ({
  studentData,
  loading,
  onDelete,
  onSave,
  setStudentData,
}) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (studentData.id) {
      onDelete(studentData.id);
    } else {
      console.warn("Student ID is missing or undefined.");
    }
    handleCloseDialog();
  };

  return (
    <Grid item xs={12} key={studentData.id}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h5" component="div">
          Editar Jugador
        </Typography>
        <IconButton onClick={handleOpenDialog} disabled={loading} color="error">
          <DeleteForeverIcon />
        </IconButton>
      </Box>
      <StudentForm studentData={studentData} setStudentData={setStudentData} />
      <Grid item xs={12} sx={{ mt: 2, textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={() => {
            if (studentData.id) {
              onSave(studentData.id);
            } else {
              console.warn("Student ID is missing or undefined.");
            }
          }}
        >
          {loading ? "Guardando..." : "Guardar"}
        </Button>
      </Grid>
      <Divider sx={{ mb: 2, mt: 4 }} />
      <ConfirmDialog
        title="Eliminar Jugador"
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        content="¿Estás seguro de que deseas eliminar a este jugador?"
      >
        ¿Estás seguro de que deseas eliminar a este jugador?
      </ConfirmDialog>
    </Grid>
  );
};

export default StudentItem;
