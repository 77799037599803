import { useState } from "react";
import { useNavigate } from "react-router";
import { Grid, Button, Divider, Typography } from "@mui/material";
import StudentForm from "../../components/Layouts/Forms/StudentForm";
import TutorForm from "../../components/Layouts/Forms/TutorForm";
import {
  useCreateStudent,
  useCreateTutor,
  useGetUserStudentnTutor,
} from "../../components/apiRequest/StudentAndTutorRequests";

function AccountFormNew() {
  const navigate = useNavigate();
  const [studentData, setStudentData] = useState({
    first_name: "",
    last_name: "",
    birthdate: "",
    weight: "",
    school: "",
    school_grade: "",
    school_level: "",
    address: "",
  });
  const [tutorData, setTutorData] = useState({
    first_name: "",
    last_name: "",
    company: "",
    company_role: "",
    phone: "",
    email: "",
  });

  const { students, tutors, isLoading, isError } = useGetUserStudentnTutor();

  const handleAtrasClick = () => {
    navigate("/cuenta");
  };

  // Post Functions

  const {
    loading: studentLoading,
    error: studentError,
    createStudent,
  } = useCreateStudent(studentData, students);

  const {
    loading: tutorLoading,
    error: tutorError,
    createTutor,
  } = useCreateTutor(tutorData, tutors);

  return (
    <Grid
      container
      spacing={2}
      sx={{ alignItems: "center", width: "100%", display: "flex" }}
    >
      <Grid container item xs={12} sx={{ mt: 2 }}>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography variant="h5" gutterBottom>
            Crear Jugador
          </Typography>
        </Grid>
        <StudentForm
          studentData={studentData}
          setStudentData={setStudentData}
        />
        <Grid container justifyContent="flex-end">
          <Button onClick={() => createStudent(studentData, students)}>
            Agregar
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ mb: 4 }} />
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography variant="h5" gutterBottom>
            Crear Tutor @
          </Typography>
        </Grid>
        <TutorForm tutorData={tutorData} setTutorData={setTutorData} />
        <Grid container justifyContent="flex-end">
          <Button onClick={() => createTutor(tutorData, tutors)}>
            Agregar
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ mb: 4 }} />
      </Grid>
    </Grid>
  );
}

export default AccountFormNew;
