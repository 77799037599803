import { Grid, Typography } from "@mui/material";
import axiosInstance from "../../api/axiosInstance";
import { useEffect, useState } from "react";
import StudentList from "./StudentList";
import ContentLayout from "../../components/Layouts/ContentLayout/ContentLayout";

function StudentSelect() {
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const getStudent = process.env.REACT_APP_GET_ALL_STUDENTS;

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await axiosInstance.get(`${getStudent}`);
        setStudents(response.data.data.students);
        setIsLoading(false);
        console.log(response.data.data.students);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An error occurred");
        }
        setIsLoading(false);
        console.log(err);
      }
    };
    fetchStudents();
  }, []);

  return (
    <ContentLayout>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Seleccione un Jugador
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <Typography variant="body1">Cargando...</Typography>
        ) : error ? (
          <Typography variant="body1">
            Error en la petición del servidor
          </Typography>
        ) : students.length === 0 ? (
          <Typography variant="body1">No hay información disponible</Typography>
        ) : (
          <StudentList students={students} />
        )}
      </Grid>
    </ContentLayout>
  );
}

export default StudentSelect;
