import React from "react";
import { Student } from "../../interfaces/student";
import { Document } from "../../interfaces/document";
import { categoryMap } from "../../interfaces/categorylist";
import format from "date-fns/format";

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  ButtonGroup,
  Button,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../styles/theme";

interface UserTableProps {
  students: Student[];
  count: number;
  page: number;
  rowsPerPage: number;
  updatePage: (page: number) => void;
  updateRows: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PUserTable: React.FC<UserTableProps> = ({
  students,
  count,
  page,
  rowsPerPage,
  updatePage,
  updateRows,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const combinedBreakpoint = useMediaQuery(
    "(max-width:985px) and (min-width:600px)"
  );

  const customBreakpoint = useMediaQuery(
    "(min-width:986px) and (max-width:1158px)"
  );

  const tableStyle: React.CSSProperties = {
    overflowX: "hidden",
    width: isMobile ? "auto" : "100%",
    tableLayout: isMobile ? "fixed" : "auto",
  };

  return (
    <Table style={isMobile ? tableStyle : {}}>
      <TableHead>
        <TableRow>
          <TableCell>Nombre</TableCell>
          {!combinedBreakpoint && !isMobile && <TableCell>Apellido</TableCell>}
          {!isMobile && <TableCell>Categoría</TableCell>}
          {!combinedBreakpoint && !isMobile && (
            <TableCell>
              {customBreakpoint ? "Tutor" : "Nombre del Tutor"}
            </TableCell>
          )}
          {combinedBreakpoint && <TableCell>Recibos</TableCell>}
          {!combinedBreakpoint && !isMobile && (
            <TableCell>
              {customBreakpoint ? "Recibo" : "Último Recibo de Pago"}
            </TableCell>
          )}
          {isMobile && <TableCell>Recibos</TableCell>}
          <TableCell>Acciones</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {students.map((student) => (
          <TableRow key={student.id}>
            <TableCell>{`${student.first_name} ${
              combinedBreakpoint || isMobile ? " " + student.last_name : ""
            }`}</TableCell>
            {!combinedBreakpoint && !isMobile && (
              <TableCell>{student.last_name}</TableCell>
            )}
            {!isMobile && (
              <TableCell>
                {categoryMap[student.category_id as keyof typeof categoryMap] ||
                  "N/A"}
              </TableCell>
            )}
            {!combinedBreakpoint && !isMobile && (
              <TableCell>
                {student.user &&
                student.user.tutors &&
                student.user.tutors.length > 0
                  ? `${student.user.tutors[0].first_name} ${student.user.tutors[0].last_name}`
                  : "N/A"}
              </TableCell>
            )}
            {!isMobile && (
              <TableCell>
                {student.student_documents &&
                student.student_documents.length > 0 &&
                student.student_documents[0].created_at
                  ? format(
                      new Date(student.student_documents[0].created_at),
                      "yyyy-MM-dd"
                    )
                  : "N/A"}
              </TableCell>
            )}
            {isMobile && (
              <TableCell>
                {student.student_documents &&
                student.student_documents.length > 0 &&
                student.student_documents[0].created_at
                  ? format(
                      new Date(student.student_documents[0].created_at),
                      "yyyy-MM-dd"
                    )
                  : "N/A"}
              </TableCell>
            )}
            <TableCell>
              <ButtonGroup size="small">
                <Button component={Link} to={`/alumnos/${student.id}/edit`}>
                  Ver
                </Button>
              </ButtonGroup>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            showFirstButton
            showLastButton
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 20, 50]}
            onPageChange={(e, p) => updatePage(p)}
            onRowsPerPageChange={updateRows}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
};

export default PUserTable;
