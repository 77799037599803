import { Fragment } from "react";
import { Hidden, Box, Button } from "@mui/material";
import { useDropzone } from "react-dropzone";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import theme from "../../styles/theme";

function FileInput({ onDrop }: { onDrop: (files: File[]) => void }) {
  const acceptedFileTypes = "application/pdf, image/jpeg, image/png, image/gif";

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (accepted) => onDrop(accepted),
    accept: acceptedFileTypes as any,
  });

  return (
    <Fragment>
      <Hidden lgDown>
        <Box
          {...getRootProps()}
          sx={{
            border: "1px dashed",
            margin: "10px",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <input {...getInputProps()} />
          <UploadFileIcon
            fontSize="large"
            sx={{ mb: 1, color: theme.palette.primary.main }}
          />
          {isDragActive ? (
            <p>Suelta los archivos aquí...</p>
          ) : (
            <p>
              Arrastra y suelta archivos aquí, o haz clic para seleccionarlos
            </p>
          )}
        </Box>
      </Hidden>
      <Hidden lgUp>
        <Button variant="contained" component="label" sx={{ mb: 1 }}>
          Subir Archivos
          <input
            type="file"
            hidden
            accept={acceptedFileTypes}
            onChange={(e) => {
              if (e.target.files) {
                onDrop(Array.from(e.target.files));
              }
            }}
          />
        </Button>
      </Hidden>
    </Fragment>
  );
}

export default FileInput;
