import axiosInstance from "../../api/axiosInstance";
import { useSnackbar } from "notistack";
import { Typography, Box } from "@mui/material";
import { Fragment } from "react";
import { Document } from "../../interfaces/document";
import { ShowUplDocuments } from "../../components/ShowUplDocuments/ShowUplDocuments";

interface IUploadedDocumentsProps {
  userId: string;
  userType: "student" | "tutor";
  documentList: Document[];
  error: boolean;
  errorMessage: string;
  document_rejected_reasons_id?: number;
  document_rejected_reason?: {
    id: number;
    title: string;
    created_at: string;
    updated_at: string | null;
    deleted_at: string | null;
  };
}

interface IDocumentsDisplayProps {
  documentList: Document[];
  userType: "student" | "tutor";
}

function ErrorDisplay({ errorMessage }: { errorMessage: string }) {
  return (
    <Box display="flex" justifyContent="center">
      <Typography variant="subtitle1">{errorMessage}</Typography>
    </Box>
  );
}

function NoDocumentsDisplay() {
  return (
    <Box display="flex" justifyContent="center">
      <Typography variant="subtitle1">
        No existen documentos subidos por el momento...
      </Typography>
    </Box>
  );
}

function UploadedDocuments(props: IUploadedDocumentsProps) {
  const { error, errorMessage, documentList, userType } = props;
  const { enqueueSnackbar } = useSnackbar();

  // Filtra la lista de documentos según el tipo de usuario
  const filteredDocumentList = documentList.filter((doc) =>
    userType === "student"
      ? (doc.document_id ?? 0) >= 1 && (doc.document_id ?? 0) <= 7
      : userType === "tutor" &&
        ((doc.document_id ?? 0) === 10 || (doc.document_id ?? 0) === 11)
  );

  const handleDelete = async (docId: string) => {
    let deleteUrl = "";

    if (userType === "student") {
      deleteUrl = process.env.REACT_APP_GET_STUDENT_DOCUMENTS as string;
    } else if (userType === "tutor") {
      deleteUrl = process.env.REACT_APP_GET_TUTOR_DOCUMENTS as string;
    }

    deleteUrl = `${deleteUrl}/${docId}`;

    try {
      await axiosInstance.delete(deleteUrl);
      enqueueSnackbar("Documento eliminado exitosamente", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Hubo un error al eliminar el documento", {
        variant: "error",
      });
    }
  };

  const handleDownload = (docId: string) => {
    console.log(`Downloading document with id ${docId}`);
    const document = documentList.find((doc) => doc.id.toString() === docId);
    if (document && document.file_path) {
      window.open(document.file_path, "_blank", "noopener noreferrer");
    }
  };

  return (
    <Fragment>
      {error ? (
        <Typography variant="subtitle1">{errorMessage}</Typography>
      ) : filteredDocumentList.length === 0 ? (
        <Typography variant="subtitle1">
          No existen documentos subidos por el momento...
        </Typography>
      ) : (
        <ShowUplDocuments
          documentList={filteredDocumentList}
          onDelete={handleDelete}
          onDownload={handleDownload}
        />
      )}
    </Fragment>
  );
}

export default UploadedDocuments;
