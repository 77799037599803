import React, { useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Collapse,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import UserGuide from "./UserGuide";
import { adminTabs } from "../../components/Text/tabsAdminData";
import ContentLayout from "../../components/Layouts/ContentLayout/ContentLayout";

function MainAdmin() {
  const [openTab, setOpenTab] = useState<number | null>(null);

  const handleClick = (index: number) => {
    setOpenTab(openTab === index ? null : index);
  };

  return (
    <ContentLayout
      title="Guía del Administrador"
      paperStyles={{ height: "100%" }}
      showDivider
    >
      <Typography variant="body1" sx={{ mb: 3 }}>
        Bienvenido a la sección administrativa de la aplicación Rojos. Aquí
        podrás encontrar una guía detallada sobre las funcionalidades
        disponibles en cada pestaña.
      </Typography>
      <List>
        {adminTabs.map((tab, index) => (
          <div key={index}>
            <ListItem
              button
              onClick={() => handleClick(index)}
              sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
            >
              <ListItemText
                primary={tab.title}
                primaryTypographyProps={{
                  sx: {
                    fontWeight: "bold",
                  },
                }}
              />
            </ListItem>
            <Collapse in={openTab === index} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem>
                  {tab.description.subTitle && (
                    <ListItemText secondary={tab.description.subTitle} />
                  )}
                </ListItem>
                {tab.description.steps.map((step, stepIndex) => (
                  <UserGuide
                    key={stepIndex}
                    title={step.title}
                    text={step.text}
                    image={step.image ?? ""}
                  />
                ))}
              </List>
            </Collapse>
          </div>
        ))}
      </List>
    </ContentLayout>
  );
}

export default MainAdmin;
