import { Route, Routes } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import RequireAuth from "./components/RequireAuth/RequireAuth";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import SignUp from "./pages/SingUp/SignUp";
import Leads from "./pages/Leads/Leads";
import Navigation from "./components/Navigation/Navigation";
import Account from "./pages/Account/Account";
import Payments from "./pages/Payments/Payments";
import Documents from "./pages/Documents/Documents";
import Notifications from "./pages/Notifications";
import AdminSignUp from "./pages/SingUp/AdminSignUp";
import AdminOrUserMain from "./components/AdminOrUserMain/AdminOrUserMain";
import AdminPayments from "./pages/AdminPayments/AdminPayments";
import Users from "./pages/Users/Users";
import NewAccount from "./pages/NewAccount/NewAccount";
import Settings from "./pages/Settings/Settings";
import RecoverPassword from "./pages/RecoverPassword/RecoverPassword";
import "./styles/main.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./styles/theme";

// <RequireAuth adminRequired={true}> for admin pages only

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route
          path="/leads"
          element={
            <SnackbarProvider>
              <Leads />
            </SnackbarProvider>
          }
        />
        <Route
          path="/iniciar"
          element={
            <SnackbarProvider>
              <Login />
            </SnackbarProvider>
          }
        />
        <Route
          path="/registro"
          element={
            <SnackbarProvider>
              <SignUp />
            </SnackbarProvider>
          }
        />
        <Route
          path="/recuperar"
          element={
            <SnackbarProvider>
              <RecoverPassword />
            </SnackbarProvider>
          }
        />

        <Route path="/*" element={<Navigation />}>
          <Route
            index
            element={
              <SnackbarProvider>
                <RequireAuth>
                  <AdminOrUserMain />
                </RequireAuth>
              </SnackbarProvider>
            }
          />
          <Route
            path="nueva-cuenta/*"
            element={
              <SnackbarProvider>
                <RequireAuth>
                  <NewAccount />
                </RequireAuth>
              </SnackbarProvider>
            }
          />
          <Route
            path="alumnos/*"
            element={
              <SnackbarProvider>
                <RequireAuth>
                  <Users />
                </RequireAuth>
              </SnackbarProvider>
            }
          />
          <Route
            path="cuenta/*"
            element={
              <SnackbarProvider>
                <RequireAuth>
                  <Account />
                </RequireAuth>
              </SnackbarProvider>
            }
          />
          <Route
            path="pagos"
            element={
              <SnackbarProvider>
                <RequireAuth>
                  <Payments />
                </RequireAuth>
              </SnackbarProvider>
            }
          />
          <Route
            path="documentos"
            element={
              <SnackbarProvider>
                <RequireAuth>
                  <Documents />
                </RequireAuth>
              </SnackbarProvider>
            }
          />
          <Route
            path="ajustes"
            element={
              <SnackbarProvider>
                <RequireAuth>
                  <Settings />
                </RequireAuth>
              </SnackbarProvider>
            }
          />
          <Route
            path="notificaciones"
            element={
              <RequireAuth>
                <Notifications />
              </RequireAuth>
            }
          />
          <Route
            path="pagos-admin"
            element={
              <RequireAuth adminRequired={true}>
                <AdminPayments />
              </RequireAuth>
            }
          />
          <Route
            path="admin-registro"
            element={
              <SnackbarProvider>
                <RequireAuth adminRequired={true}>
                  <AdminSignUp />
                </RequireAuth>
              </SnackbarProvider>
            }
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
