import { Box, Container, Grid, ToggleButton, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Leads } from "../../interfaces/lead";

const theme = createTheme({
  palette: {
    primary: {
      main: "#e30613",
    },
  },
});

interface ILeadProps {
  leadData: Leads;
  setLeadData: any;
}

function Schedule(props: ILeadProps) {
  const { leadData, setLeadData } = props;

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            p: 3,
          }}
        >
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography align="left" sx={{ mb: 1 }}>
                  Selecciona un horario para tomar la clase muestra:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ToggleButton
                  fullWidth
                  value="Martes 4:30 a 6:30"
                  selected={leadData.demo_class === "Martes 4:30 a 6:30"}
                  onChange={(event, value) => {
                    if (value) {
                      setLeadData({ ...leadData, demo_class: value });
                    }
                  }}
                >
                  Martes 4:30 a 6:30
                </ToggleButton>
              </Grid>
              <Grid item xs={12}>
                <ToggleButton
                  fullWidth
                  value="Jueves 4:30 a 6:30"
                  selected={leadData.demo_class === "Jueves 4:30 a 6:30"}
                  onChange={(event, value) => {
                    if (value) {
                      setLeadData({ ...leadData, demo_class: value });
                    }
                  }}
                >
                  Jueves 4:30 a 6:30
                </ToggleButton>
              </Grid>
              <Grid item xs={12}>
                <ToggleButton
                  fullWidth
                  value="Sábados 10:30 a 12:00"
                  selected={leadData.demo_class === "Sábados 10:30 a 12:00"}
                  onChange={(event, value) => {
                    if (value) {
                      setLeadData({ ...leadData, demo_class: value });
                    }
                  }}
                >
                  Sábados 10:30 a 12:00
                </ToggleButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default Schedule;
