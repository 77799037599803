import React, { useState } from "react";
import { useGeStudentsAndPayment } from "../apiRequest/StudentAndTutorRequests";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

const PaymentNotification: React.FC = () => {
  const { students } = useGeStudentsAndPayment();
  const [openDialog, setOpenDialog] = useState(false);

  const hasNotification = students.some(
    (student) => student.shouldShowNotification
  );

  console.log("Students:", students);
  console.log("Has Notification:", hasNotification);

  const handleNotificationClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="notifications"
        onClick={handleNotificationClick}
      >
        {hasNotification ? (
          <NotificationImportantIcon />
        ) : (
          <NotificationsNoneIcon />
        )}
      </IconButton>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Notificación</DialogTitle>
        <DialogContent>
          Recuerde realizar su pago el día {new Date().toLocaleDateString()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Entendido
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PaymentNotification;
