import axiosInstance from "../../api/axiosInstance";
import theme from "../../styles/theme";
import { useEffect, useState, ChangeEvent } from "react";
import {
  Button,
  ButtonGroup,
  LinearProgress,
  Typography,
  Grid,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { PaginationResults } from "../../interfaces/pagination-results";
import { Student } from "../../interfaces/student";
import { categoryMap } from "../../interfaces/categorylist";
import PUserTable from "./PUserTable";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContentLayout from "../../components/Layouts/ContentLayout/ContentLayout";
import FilterListIcon from "@mui/icons-material/FilterList";

interface ResponseData {
  data: PaginationResults<Student>;
}

interface AxiosResponse {
  data: ResponseData;
}

function PUsersList() {
  const [students, setStudents] = useState<Student[]>([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredStudents, setFilteredStudents] = useState<Student[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // Estado para controlar el menú
  const [categoryFilter, setCategoryFilter] = useState<number | null>(null); // Estado para el filtro
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null); // Estado para manejar la clasificación
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Filtrado de Asc y Desc
  const handleSortOrder = (order: "asc" | "desc" | null) => {
    if (sortOrder === order) {
      setSortOrder(null); // Si se clickea el mismo botón, se restablece el orden
    } else {
      setSortOrder(order);
    }
  };

  useEffect(() => {
    (async () => {
      const skip = page + 1;
      const limit = rowsPerPage;

      try {
        const response = await fetchStudents(limit, skip);
        console.log("Respuesta de la API:", response.data.data);
        const results = response.data.data;

        // Filtrado por categorías
        if (results) {
          let filteredStudents = results.data;
          if (categoryFilter !== null) {
            filteredStudents = filteredStudents.filter((student) => {
              if (student.category_id !== undefined) {
                return parseInt(student.category_id, 10) === categoryFilter; // Conversión a Número
              }
              return false;
            });
          }

          // Ordenar en asc y desc
          if (sortOrder) {
            filteredStudents = filteredStudents.sort((a, b) => {
              if (sortOrder === "asc") {
                return a.last_name.localeCompare(b.last_name);
              } else {
                return b.last_name.localeCompare(a.last_name);
              }
            });
          }

          setStudents(filteredStudents);
          setCount(results.total);
          setPage(results.current_page - 1);
        }
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    })();
  }, [page, rowsPerPage, categoryFilter, sortOrder]);

  const fetchStudents = async (
    limit: number,
    skip: number
  ): Promise<AxiosResponse> => {
    return await axiosInstance.get(`/all-students?limit=${limit}&page=${skip}`);
  };

  //Paginación
  const updatePage = (newPage: number) => {
    setPage(newPage);
  };

  const updateRows = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const Loading = <LinearProgress color="inherit" sx={{ m: 5 }} />;

  // Filtrado por categorías

  //Drop Down Button
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handler del filtro por categorías
  const handleFilter = (categoryKey: number | null) => {
    // Permitir 'null'
    setCategoryFilter(categoryKey);
    handleClose();
  };

  const containerStyle: React.CSSProperties = {
    overflowX: "hidden", // Aseguramos que el contenedor no tiene desbordamientos en el eje x
    width: "100%",
  };

  return (
    <ContentLayout paperStyles={{ height: "100%" }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          px: 2,
          mb: 2,
          borderBottom: "1px solid #d3d3d3",
        }}
      >
        <Grid
          item
          container
          direction={isMobile ? "column" : "row"}
          justifyContent={isMobile ? "center" : "space-between"}
          alignItems="center"
          xs={12}
        >
          <Grid
            item
            xs={isMobile ? 12 : 6}
            sx={{
              mb: isMobile ? 2 : 0,
              textAlign: "left",
            }}
          >
            <Typography
              component="h2"
              variant={isMobile ? "h6" : "h5"}
              gutterBottom
            >
              Recibos
            </Typography>
          </Grid>
          <Grid
            item
            xs={isMobile ? 12 : 6}
            sx={{
              mb: isMobile ? 2 : 0,
              textAlign: "right",
            }}
          >
            <ButtonGroup size={isMobile ? "small" : "medium"}>
              <IconButton onClick={() => handleSortOrder("asc")}>
                <ExpandLessIcon />
              </IconButton>
              <IconButton onClick={() => handleSortOrder("desc")}>
                <ExpandMoreIcon />
              </IconButton>
              <IconButton onClick={handleClick}>
                <FilterListIcon />
              </IconButton>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Grid>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleFilter(null)}>
          Todas las categorías
        </MenuItem>
        {Object.keys(categoryMap).map((key) => (
          <MenuItem key={key} onClick={() => handleFilter(parseInt(key))}>
            {categoryMap[key]}
          </MenuItem>
        ))}
      </Menu>

      {!students ? (
        <LinearProgress color="inherit" sx={{ m: 5 }} />
      ) : (
        <PUserTable
          students={students}
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          updatePage={updatePage}
          updateRows={updateRows}
        />
      )}
    </ContentLayout>
  );
}

export default PUsersList;
