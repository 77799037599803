import { useState, useEffect, Fragment } from "react";
import axiosInstance from "../../api/axiosInstance";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../styles/theme";
import UploadedDocuments from "./UploadedPayments";
import UploadDocuments from "./UploadPayment";
import { Document } from "../../interfaces/document";

type UserDocumentSelectProps = { studentId: string };

function PaymentSelect(props: UserDocumentSelectProps) {
  const { studentId } = props;
  const [documentList, setDocumentList] = useState<Document[]>([]);
  const [uploadedDocuments, setUploadedDocuments] = useState<Document[]>([]);
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(
    null
  );
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const docsURL = process.env.REACT_APP_DOCUMENT_LIST;
  const studentDocs = process.env.REACT_APP_GET_STUDENT_DOCUMENTS;

  //Get Docs
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await axiosInstance.get(`${docsURL}`);
        setDocumentList(response.data.data.documents);
      } catch (err) {
        console.log(err);
      }
    };
    fetchDocuments();
  }, []);

  // Get Student Docs
  useEffect(() => {
    const fetchUploadedDocuments = async () => {
      try {
        const response = await axiosInstance.get(`${studentDocs}/${studentId}`);
        setUploadedDocuments(response.data.data.documents);
        console.log(response.data);
      } catch (err) {
        console.log(err);
        setError(true);
        setErrorMessage(
          "Se tuvo problemas recuperando la información, por favor intente más tarde"
        );
      }
    };
    fetchUploadedDocuments();
  }, [studentId]);

  // Aviso de documento seleccionado
  useEffect(() => {
    console.log("Documento seleccionado:", selectedDocumentId);
  }, [selectedDocumentId]);

  const handleDocumentSelect = (event: SelectChangeEvent<string>) => {
    setSelectedDocumentId(event.target.value as string);
  };

  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ mb: 3, mt: 3 }}>
              Documentos Subidos
            </Typography>
            <UploadedDocuments
              userId={studentId}
              documentList={uploadedDocuments}
              error={error}
              errorMessage={errorMessage}
            />

            <Typography variant="h6" sx={{ mb: 3, mt: 3 }}>
              Subir Documentos
            </Typography>

            <InputLabel id="document-type-label">
              Seleccione el tipo de documento
            </InputLabel>
            <Select
              value={selectedDocumentId || ""}
              onChange={handleDocumentSelect}
              sx={{ mb: 2, width: "100%" }}
              labelId="document-type-label"
              label="Seleccione el tipo de documento"
            >
              {documentList.map((option) => {
                if (option.id !== 9) return null;

                return (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    sx={{ width: "100%" }}
                  >
                    {option.title}
                  </MenuItem>
                );
              })}
            </Select>

            {selectedDocumentId && (
              <UploadDocuments
                userId={studentId}
                documentId={selectedDocumentId}
              />
            )}
          </Grid>
        </Grid>
      </Fragment>
    </ThemeProvider>
  );
}

export default PaymentSelect;
