import { Grid } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import PUsersList from "./PUsersList";
import EditUsersPayments from "./EditUsersPayments";

function AdminPayments() {
  return (
    <Grid container item xs={12}>
      <Routes>
        <Route path="/" element={<PUsersList />} />
        <Route path=":id/edit" element={<EditUsersPayments />} />
      </Routes>
    </Grid>
  );
}

export default AdminPayments;
