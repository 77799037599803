import axiosInstance from "../../api/axiosInstance";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { styled } from "@mui/system";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DownloadIcon from "@mui/icons-material/Download";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PendingIcon from "@mui/icons-material/Pending";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";

interface IDocument {
  id: string;
  title: string;
  document_status_id: number;
  document_id?: number;
  file_path?: string;
  document_rejected_reasons_id?: number;
  document_rejected_reason?: {
    id: number;
    title: string;
    created_at: string;
    updated_at: string | null;
    deleted_at: string | null;
  };
}

const GreenTaskAltIcon = styled(TaskAltIcon)({
  color: "green",
});

const RedHighlightOffIcon = styled(HighlightOffIcon)({
  color: "red",
});

function EditStudentDocuments() {
  const { enqueueSnackbar } = useSnackbar();
  const { id: studentId } = useParams<{ id: string }>();
  const [open, setOpen] = useState(false);
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [anchorEl, setAnchorEl] = useState<{
    [key: string]: null | HTMLElement;
  }>({});
  const openMenu = Boolean(anchorEl);

  const [isRejectReasonsOpen, setIsRejectReasonsOpen] = useState(false);
  const [currentDocumentId, setCurrentDocumentId] = useState<string | null>(
    null
  );

  const getDoc = process.env.REACT_APP_GET_STUDENT_DOCUMENTS;
  const delDoc = process.env.REACT_APP_GET_STUDENT_DOCUMENTS;
  const putStudentDoc = process.env.REACT_APP_STUDENT_DOCUMENTS;

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await axiosInstance.get(`${getDoc}/${studentId}`);
        console.log(
          "Student's documents response data:",
          response.data.data.documents
        );
        setDocuments(response.data.data.documents);
      } catch (error) {
        console.error(error);
      }
    };
    fetchDocuments();
  }, [studentId]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleMenu = (id: string) => (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl((prevState) => ({ ...prevState, [id]: event.currentTarget }));
  };

  const handleClose = (id: string) => {
    setAnchorEl((prevState) => ({ ...prevState, [id]: null }));
  };

  // Document Statuses
  const getIconByStatus = (statusId: number | undefined) => {
    if (statusId === undefined) {
      return <PendingIcon />;
    }

    switch (statusId) {
      case 1:
        return <PendingIcon />;
      case 2:
        return <GreenTaskAltIcon />;
      case 3:
        return <RedHighlightOffIcon />;
      default:
        return <PendingIcon />;
    }
  };

  // Request Functions
  const handleApprove = async (id: string) => {
    console.log(`Approving document with id ${id}`);
    try {
      await axiosInstance.put(`${putStudentDoc}/${id}`, {
        document_status_id: 2,
      });
      enqueueSnackbar("Se a editado exitosamente", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("hubo un error al cambiar el estado", {
        variant: "error",
      });
    }
    handleClose(id);
  };

  const handleReject = async (id: string) => {
    console.log(`Rejecting document with id ${id}`);
    try {
      await axiosInstance.put(`${putStudentDoc}/${id}`, {
        document_status_id: 3,
      });
      enqueueSnackbar("Se a editado exitosamente", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("hubo un error al cambiar el estado", {
        variant: "error",
      });
    }
    handleClose(id);
  };

  const handleDelete = async (id: string) => {
    console.log(`Deleting document with id ${id}`);
    try {
      await axiosInstance.delete(`${delDoc}/${id}`);
      setDocuments(documents.filter((document) => document.id !== id));
      enqueueSnackbar("Documento eliminado exitosamente", {
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Hubo un error al eliminar el documento", {
        variant: "error",
      });
    }
    handleClose(id);
  };

  const handleDownload = (id: string) => {
    console.log(`Downloading student's document with id ${id}`);
    const document = documents.find((doc) => doc.id === id);
    if (document) {
      const filePath = document.file_path;
      window.open(filePath, "_blank", "noopener noreferrer");
    }
    handleClose(id);
  };

  const handleRejectedReasonChange = async (
    documentId: string,
    reasonId: number
  ) => {
    try {
      await axiosInstance.put(
        `student-document/${documentId}/rejected-reason`,
        {
          document_rejected_reason_id: reasonId,
        }
      );
      enqueueSnackbar("Razón de rechazo actualizada con éxito", {
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Hubo un error al actualizar la razón de rechazo", {
        variant: "error",
      });
    }
    closeRejectReasons();
  };

  const openRejectReasons = (documentId: string) => {
    setCurrentDocumentId(documentId);
    setIsRejectReasonsOpen(true);
  };

  const closeRejectReasons = () => {
    setCurrentDocumentId(null);
    setIsRejectReasonsOpen(false);
  };

  const renderDocuments = () => {
    const filteredDocuments = documents.filter(
      (document) => document.document_id && document.document_id <= 7
    );

    if (filteredDocuments.length === 0) {
      return <ListItem>No se encontraron documentos por el momento</ListItem>;
    }
    return filteredDocuments.map(
      ({ id, title, document_status_id, document_rejected_reason }) => (
        <ListItem key={id} divider={true}>
          <ListItemIcon>{getIconByStatus(document_status_id)}</ListItemIcon>
          <ListItemText
            primary={title}
            secondary={
              document_rejected_reason ? document_rejected_reason.title : null
            }
          />
          <IconButton onClick={handleMenu(id)}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl[id]}
            open={Boolean(anchorEl[id])}
            onClose={() => handleClose(id)}
          >
            <MenuItem onClick={() => handleDownload(id)}>
              <DownloadIcon />
            </MenuItem>
            <MenuItem onClick={() => handleApprove(id)}>
              <CheckCircleOutlineIcon />
            </MenuItem>
            <MenuItem onClick={() => handleReject(id)}>
              <DoDisturbAltIcon />
            </MenuItem>
            <MenuItem onClick={() => handleDelete(id)}>
              <DeleteForeverIcon />
            </MenuItem>
            <MenuItem onClick={() => openRejectReasons(id)}>
              <ReportGmailerrorredIcon />
            </MenuItem>
          </Menu>
          {isRejectReasonsOpen && currentDocumentId === id && (
            <Menu
              anchorEl={anchorEl[id]}
              open={isRejectReasonsOpen}
              onClose={closeRejectReasons}
            >
              <MenuItem onClick={() => handleRejectedReasonChange(id, 1)}>
                Documento Incorrecto
              </MenuItem>
              <MenuItem onClick={() => handleRejectedReasonChange(id, 2)}>
                Documento Ilegible
              </MenuItem>
              <MenuItem onClick={() => handleRejectedReasonChange(id, 3)}>
                INE Incompleto
              </MenuItem>
              <MenuItem onClick={() => handleRejectedReasonChange(id, 4)}>
                Documento rechazado
              </MenuItem>
            </Menu>
          )}
        </ListItem>
      )
    );
  };

  return (
    <>
      <List>{renderDocuments()}</List>
    </>
  );
}

export default EditStudentDocuments;
