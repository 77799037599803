import axiosInstance from "../../api/axiosInstance";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Tutor } from "../../interfaces/tutor";
import EditTutorTable from "./EditTutorTable";
import EditTutorDocuments from "./EditTutorDocuments";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../styles/theme";
import AdminTutorsContainer from "../../components/Layouts/TabbedContainer/Containers/AdminTutorsContainer";

function EditTutor() {
  const { id: studentId } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [tutors, setTutors] = useState<Tutor[]>([]);
  const [activeTab, setActiveTab] = useState<"table" | "documents">("table");
  const [selectedTutorIndex, setSelectedTutorIndex] = useState(0);

  const studentReq = process.env.REACT_APP_STUDENT;
  const tutorReq = process.env.REACT_APP_TUTOR;

  useEffect(() => {
    const fetchTutor = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get(`${studentReq}/${studentId}`);
        console.log(
          "Tutors's response data:",
          response.data.data.student.tutors
        );
        setTutors(response.data.data.student.tutors);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    fetchTutor();
  }, [studentId]);

  const handleChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setTutors((prev) => {
        const newTutors = [...prev];
        newTutors[index][event.target.name as keyof Tutor] = event.target.value;
        return newTutors;
      });
    };

  const handleSubmit = async (index: number) => {
    setIsLoading(true);
    try {
      console.log("Tutor data sent:", tutors[index]);
      const response = await axiosInstance.put(
        `${tutorReq}/${tutors[index].id}`,
        tutors[index]
      );
      console.log(response.data);
      enqueueSnackbar("Tutor editado exitosamente", { variant: "success" });
    } catch (err) {
      console.log(err);
      enqueueSnackbar("hubo un error al editar el tutor", {
        variant: "error",
      });
    }
    setIsLoading(false);
  };

  const tabs = [
    { label: "Tutor", value: "table" },
    { label: "Documentos", value: "documents" },
  ];

  if (!studentId) {
    return <div>Error: No se proporcionó un ID de estudiante.</div>;
  }

  let tutorDocumentComponent = null;
  if (tutors[selectedTutorIndex] && tutors[selectedTutorIndex].id) {
    tutorDocumentComponent = (
      <EditTutorDocuments tutorId={tutors[selectedTutorIndex].id!} />
    );
  }

  const handleTutorSelection = (index: number) => {
    setSelectedTutorIndex(index);
  };

  return (
    <ThemeProvider theme={theme}>
      <AdminTutorsContainer
        activeTab={activeTab}
        onTabClick={(tab) => setActiveTab(tab as "table" | "documents")}
        tabs={tabs}
        studentId={studentId}
        tutors={tutors}
        selectedTutorIndex={selectedTutorIndex}
        handleTutorSelection={handleTutorSelection}
      >
        {activeTab === "table" && (
          <EditTutorTable
            tutor={tutors[selectedTutorIndex]}
            handleChange={handleChange(selectedTutorIndex)}
            isLoading={isLoading}
            handleSubmit={() => handleSubmit(selectedTutorIndex)}
          />
        )}
        {activeTab === "documents" && tutors[selectedTutorIndex] && (
          <EditTutorDocuments tutorId={tutors[selectedTutorIndex].id!} />
        )}
      </AdminTutorsContainer>
    </ThemeProvider>
  );
}

export default EditTutor;
