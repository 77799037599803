import React, { useState } from "react";
import { Select, MenuItem, Typography, Box, Grid } from "@mui/material";
import { useGetAllStudents } from "../../../components/apiRequest/StudentAndTutorRequests";
import { Student } from "../../../interfaces/student";
import UploadPhoto from "./UploadPhoto";
import UploadedPhoto from "./UploadedPhoto";

function AccountPhoto() {
  const [selectedStudentId, setSelectedStudentId] = useState<string | null>(
    null
  );
  const { students, isLoading, isError } = useGetAllStudents();

  if (isLoading) return <Typography>Cargando estudiantes...</Typography>;
  if (isError) return <Typography>Error al cargar estudiantes.</Typography>;

  return (
    <Box>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h6">Foto del jugador</Typography>
        </Grid>
        <Grid item>
          <Select
            value={selectedStudentId || ""}
            onChange={(e) => setSelectedStudentId(e.target.value as string)}
            displayEmpty
            style={{ marginBottom: "16px" }}
          >
            <MenuItem value="" disabled>
              Seleccione a un jugador:
            </MenuItem>
            {students.map((student: Student) => (
              <MenuItem key={student.id} value={student.id}>
                {student.first_name} {student.last_name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {selectedStudentId && (
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ marginBottom: "16px" }}>
              <UploadedPhoto userId={selectedStudentId} />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "16px" }}>
              <UploadPhoto
                userId={selectedStudentId}
                documentId="8"
                userType="student"
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default AccountPhoto;
