import { useState, useEffect, Fragment } from "react";
import axiosInstance from "../../api/axiosInstance";
import { Document } from "../../interfaces/document";
import DocumentsList from "./DocumentsList";
import UserSelect from "./UserSelect";
import ContentLayout from "../../components/Layouts/ContentLayout/ContentLayout";

function Documents() {
  const [documentList, setDocumentList] = useState<Document[]>([]);
  const docsURL = process.env.REACT_APP_DOCUMENT_LIST;

  //Get Docs
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await axiosInstance.get(`${docsURL}`);
        setDocumentList(response.data.data.documents);
      } catch (err) {
        console.log(err);
      }
    };
    fetchDocuments();
  }, []);

  return (
    <Fragment>
      <ContentLayout title="Lista de documentos">
        <DocumentsList documentList={documentList} />
      </ContentLayout>
      <UserSelect />
    </Fragment>
  );
}

export default Documents;
