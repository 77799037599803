import { useCallback, useState, Fragment } from "react";
import { Button, Box, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../styles/theme";
import { useSnackbar } from "notistack";
import axiosInstance from "../../api/axiosInstance";
import FileInput from "../FileInput/FileInput";

interface DocumentType {
  id: number;
  student_id: string;
  document_id: number;
  // ... otros campos que pueda tener un documento
}

interface IDocumentUploaderProps {
  userId: string;
  documentId: string;
  userType: "student" | "tutor";
}

export const DocumentUploader: React.FC<IDocumentUploaderProps> = ({
  userId,
  documentId,
  userType,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const studentDocs = process.env.REACT_APP_STUDENT_DOCUMENTS;
  const tutorDocs = process.env.REACT_APP_TUTOR_DOCUMENTS;
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const apiLink = userType === "student" ? studentDocs ?? "" : tutorDocs;

  const handleDrop = useCallback((accepted: File[]) => {
    const acceptedFileTypes =
      "application/pdf, image/jpeg, image/png, image/gif";

    const allFilesValid = accepted.every((file) =>
      acceptedFileTypes.split(", ").includes(file.type)
    );

    if (!allFilesValid) {
      alert("Solo se aceptan archivos PDF, JPEG, PNG y GIF.");
    } else {
      console.log("Archivo Cargado", accepted);
      setAcceptedFiles(accepted);
      setIsUploading(true);
    }
  }, []);

  const handleSubmit = async () => {
    if (documentId === "8") {
      // Primero, obtén todos los documentos del estudiante
      const getDocsUrl = `${process.env.REACT_APP_GET_STUDENT_DOCUMENTS}/${userId}`;
      try {
        const response = await axiosInstance.get(getDocsUrl);
        const documents = response.data.data.documents;

        // Busca si ya existe un documento con document_id = 8
        const existingDoc = documents.find(
          (doc: DocumentType) => doc.document_id === 8
        );

        // Si existe, elimínalo
        if (existingDoc) {
          const deleteUrl = `${process.env.REACT_APP_GET_STUDENT_DOCUMENTS}/${existingDoc.id}`;
          const deleteResponse = await axiosInstance.delete(deleteUrl);
          if (deleteResponse.data.success) {
            console.log("Documento eliminado exitosamente");
          } else {
            console.error(
              "Error al eliminar el documento:",
              deleteResponse.data.message
            );
          }
        }
      } catch (error) {
        console.error(
          "Hubo un error al obtener o eliminar el documento",
          error
        );
      }
    }

    console.log("Archivos aceptados:", acceptedFiles);
    console.log("Document ID:", documentId);
    const formData = new FormData();
    formData.append(userType === "student" ? "student_id" : "tutor_id", userId);
    formData.append("document_id", documentId);
    formData.append("file", acceptedFiles[0]);

    if (!apiLink) {
      console.error("API link is not defined");
      return;
    }

    try {
      const response = await axiosInstance.post(apiLink, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response.data);
      setIsUploading(false);
      setAcceptedFiles([]);
      if (parseInt(documentId, 10) === 9) {
        enqueueSnackbar(
          "Comprobante subido con éxito, en proceso de validación",
          { variant: "success" }
        );
      } else {
        enqueueSnackbar("Archivo subido exitosamente", { variant: "success" });
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar("hubo un error al subir el archivo", {
        variant: "success",
      });
    }
  };

  const handleCancelUpload = () => {
    setIsUploading(false);
    setAcceptedFiles([]);
  };

  return (
    <ThemeProvider theme={theme}>
      {isUploading ? (
        <Box>
          <Typography variant="body1">
            Archivo seleccionado: {acceptedFiles[0]?.name}
          </Typography>
          <Box display="flex" flexDirection="row" mt={2} sx={{ mb: 1 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{ mr: 1 }}
            >
              Subir
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCancelUpload}
            >
              Atrás
            </Button>
          </Box>
        </Box>
      ) : (
        <FileInput onDrop={handleDrop} />
      )}
    </ThemeProvider>
  );
};
