import React from "react";
import { Button, Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import CustomDatePickerPayments from "../../../components/DatePicker/CustomDatePickerPayments";
interface RenderUpdatePaymentProps {
  lastPaymentDate: string | null | "no-data";
  setLastPaymentDate: React.Dispatch<
    React.SetStateAction<string | null | "no-data">
  >;
  handleUpdateLastPayment: () => void;
}

const RenderUpdatePayment: React.FC<RenderUpdatePaymentProps> = ({
  lastPaymentDate,
  setLastPaymentDate,
  handleUpdateLastPayment,
}) => {
  const handleDateChange = (formattedDate: string) => {
    setLastPaymentDate(formattedDate);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      }}
    >
      {lastPaymentDate === "no-data" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <Typography variant="body1" sx={{ mb: 1 }}>
            No existe ningún pago todavía.
          </Typography>
        </Box>
      ) : null}
      <CustomDatePickerPayments
        label="Fecha de Pago"
        value={
          lastPaymentDate && lastPaymentDate !== "no-data"
            ? lastPaymentDate
            : null
        }
        onChange={handleDateChange}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpdateLastPayment}
        sx={{ mt: 2 }}
      >
        Actualizar Pago
      </Button>
    </Box>
  );
};

export default RenderUpdatePayment;
