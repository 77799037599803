import { Typography, Box, Grid } from '@mui/material'

function NotFound() {
  return (
    <Grid
      container
      spacing={0}
      direction='column'
      alignItems='center'
      justifyContent='center'
      style={{ minHeight: '100vh' }}
    >
      <Box>
        <Typography variant='h1' component='h1' align='center'>
          404
        </Typography>
        <Typography variant='subtitle1' component='p' align='center'>
          Oops! Esta página no existe
        </Typography>
      </Box>
    </Grid>
  )
}

export default NotFound
