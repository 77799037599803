import { ThemeProvider } from "@mui/material/styles";
import theme from "../../styles/theme";
import { DocumentUploader } from "../../components/DocumentUploader/DocumentUploader";

interface IUploadDocumentsProps {
  userId: string;
  documentId: string;
}

function UploadPayment({ userId, documentId }: IUploadDocumentsProps) {
  return (
    <ThemeProvider theme={theme}>
      <DocumentUploader
        userId={userId}
        documentId={documentId}
        userType="student"
      />
    </ThemeProvider>
  );
}

export default UploadPayment;
