import { useNavigate } from "react-router";
import { Button, Grid, Typography } from "@mui/material";
import { useState } from "react";

function EmptyAccount() {
  const navigate = useNavigate();

  const handleAddInfoClick = () => {
    navigate("/cuenta/nuevo");
  };

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      height="40vh"
      spacing={2}
      xs={12}
      sx={{ display: "flex" }}
    >
      <Grid item>
        <Typography variant="h6" gutterBottom>
          No hay información disponible
        </Typography>
      </Grid>
      <Grid item>
        <Button variant="contained" onClick={handleAddInfoClick}>
          Agregar información
        </Button>
      </Grid>
    </Grid>
  );
}

export default EmptyAccount;
