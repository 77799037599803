import { Grid, Typography, Divider } from "@mui/material";

function CuentaPagos() {
  return (
    <Grid container item>
      <Grid item xs={12} sx={{ mb: 1 }}>
        <Typography variant="h6">Número de cuenta</Typography>
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Typography variant="body1">
          Por favor, efectúa tu pago utilizando el número de cuenta indicado.
          Tras realizar el pago, selecciona al jugador apropiado en la
          aplicación y sube el recibo de pago correspondiente a dicho jugador.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body2"
          sx={{ textAlign: "left", fontSize: "0.875rem" }}
        >
          <strong>Banco:</strong> Banorte
          <br />
          <strong>Número de cuenta:</strong> 4189 1430 9379 0312
          <br />
          <strong>Nombre de la cuenta:</strong> María Eugenia Moya Ramos
        </Typography>
      </Grid>
    </Grid>
  );
}

export default CuentaPagos;
