import axiosInstance from "../../../api/axiosInstance";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { styled } from "@mui/system";
import {
  Typography,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import PendingIcon from "@mui/icons-material/Pending";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Student } from "../../../interfaces/student";

import RenderDocuments from "./RenderDocuments";
import RenderUpdatePayment from "./RenderUpdatePayment";
import {
  handleApprove,
  handleReject,
  handleDelete,
  handleDownload,
} from "./docPaymentsActions";
import DocumentsList from "../../Documents/DocumentsList";

interface IDocument {
  id: string;
  title: string;
  document_status_id: number;
  document_id?: number;
  file_path?: string;
}

const GreenTaskAltIcon = styled(TaskAltIcon)({
  color: "green",
});

const RedHighlightOffIcon = styled(HighlightOffIcon)({
  color: "red",
});

function EditStudentPayments() {
  const { enqueueSnackbar } = useSnackbar();
  const { id: studentId } = useParams<{ id: string }>();
  const [open, setOpen] = useState(false);
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [lastPaymentDate, setLastPaymentDate] = useState<
    string | null | "no-data"
  >("no-data");

  const [anchorEl, setAnchorEl] = useState<{
    [key: string]: null | HTMLElement;
  }>({});
  const openMenu = Boolean(anchorEl);

  const [isLoading, setIsLoading] = useState(false);
  const [student, setStudent] = useState<Student[]>([]);

  //URL
  const getDoc = process.env.REACT_APP_GET_STUDENT_DOCUMENTS;
  const delDoc = process.env.REACT_APP_GET_STUDENT_DOCUMENTS;
  const putStudentDoc = process.env.REACT_APP_STUDENT_DOCUMENTS;

  //Get Student
  const studentReq = process.env.REACT_APP_STUDENT;

  useEffect(() => {
    const fetchStudent = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get(`${studentReq}/${studentId}`);
        console.log("Students's response data:", response.data.data.student);
        setStudent(response.data.data.student);

        // Establecer la fecha de último pago
        const lastPaymentFromResponse = response.data.data.student.last_payment;
        setLastPaymentDate(lastPaymentFromResponse || "no-data");
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    fetchStudent();
  }, [studentId]);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await axiosInstance.get(`${getDoc}/${studentId}`);
        console.log(
          "Student's documents response data:",
          response.data.data.documents
        );
        setDocuments(response.data.data.documents);
      } catch (error) {
        console.error(error);
      }
    };
    fetchDocuments();
  }, [studentId]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleMenu = (id: string) => (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl((prevState) => ({ ...prevState, [id]: event.currentTarget }));
  };

  const handleClose = (id: string) => {
    setAnchorEl((prevState) => ({ ...prevState, [id]: null }));
  };

  // Document Statuses
  const getIconByStatus = (statusId: number | undefined) => {
    if (statusId === undefined) {
      return <PendingIcon />;
    }

    switch (statusId) {
      case 1:
        return <PendingIcon />;
      case 2:
        return <GreenTaskAltIcon />;
      case 3:
        return <RedHighlightOffIcon />;
      default:
        return <PendingIcon />;
    }
  };

  // Documents Actions
  const handleDocumentAction = (actionType: string, documentId: string) => {
    console.log("handleDocumentAction - actionType:", actionType);
    console.log("handleDocumentAction - documentId:", documentId);

    switch (actionType) {
      case "approve":
        handleApprove(documentId, axiosInstance, enqueueSnackbar);
        break;
      case "reject":
        handleReject(documentId, axiosInstance, enqueueSnackbar);
        break;
      case "delete":
        handleDelete(
          documentId,
          axiosInstance,
          documents,
          setDocuments,
          enqueueSnackbar
        );
        break;
      case "download":
        handleDownload(documentId, documents);
        break;
      default:
        console.error("Acción desconocida");
    }
  };

  //Put Request

  const handleUpdateLastPayment = async () => {
    const url = `${process.env.REACT_APP_API_URL}/students/${studentId}/last-payment`;
    try {
      await axiosInstance.put(url, {
        last_payment: lastPaymentDate,
      });
      enqueueSnackbar("Fecha de último pago actualizada exitosamente", {
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Hubo un error al actualizar la fecha de último pago", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    console.log("lastPaymentDate value:", lastPaymentDate);
  }, [lastPaymentDate]);

  // Estados para el año y el mes seleccionados
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

  // Manejadores para los cambios en los selectores de año y mes
  const handleYearChange = (event: SelectChangeEvent<number>) => {
    setSelectedYear(parseInt(event.target.value as string));
  };

  const handleMonthChange = (event: SelectChangeEvent<number>) => {
    setSelectedMonth(parseInt(event.target.value as string));
  };

  // Generar los MenuItem para los años
  const yearItems = [];
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year <= currentYear + 10; year++) {
    yearItems.push(
      <MenuItem key={year} value={year}>
        {year}
      </MenuItem>
    );
  }
  // Generar los MenuItem para los meses
  const monthItems = [];
  for (let month = 1; month <= 12; month++) {
    monthItems.push(
      <MenuItem key={month} value={month}>
        {month}
      </MenuItem>
    );
  }

  return (
    <>
      <Grid
        container
        item
        spacing={3}
        xs={12}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <RenderUpdatePayment
            lastPaymentDate={lastPaymentDate}
            setLastPaymentDate={setLastPaymentDate}
            handleUpdateLastPayment={handleUpdateLastPayment}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Recibo de pagos</Typography>
          <Box sx={{ mt: 2, display: "flex" }}>
            <FormControl variant="outlined">
              <InputLabel>Año</InputLabel>
              <Select
                value={selectedYear}
                onChange={handleYearChange}
                label="Año"
              >
                {yearItems}
              </Select>
            </FormControl>
            <FormControl variant="outlined">
              <InputLabel>Mes</InputLabel>
              <Select
                value={selectedMonth}
                onChange={handleMonthChange}
                label="Mes"
              >
                {monthItems}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <RenderDocuments
            documents={documents}
            anchorEl={anchorEl}
            handleClose={handleClose}
            handleMenu={handleMenu}
            getIconByStatus={getIconByStatus}
            onDocumentAction={handleDocumentAction}
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default EditStudentPayments;
