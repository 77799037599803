import { Grid, Paper } from "@mui/material";
import EditStudent from "./EditStudent";
import EditTutor from "./EditTutor";
import ReusableTabbedContainer from "../../components/Layouts/TabbedContainer/Containers/GeneralContainer";

function EditUser() {
  return (
    <Grid container item spacing={3} xs={12} sx={{ height: "100%" }}>
      <Grid item xs={12}>
        <EditStudent />
      </Grid>
      <Grid item xs={12}>
        <EditTutor />
      </Grid>
    </Grid>
  );
}

export default EditUser;
