import React, { useState, useEffect, createContext } from "react";
import { User } from "../interfaces/user";

interface AuthContextData {
  user?: User;
  isAuthenticated: boolean;
  setAuthenticated: (authStatus: boolean) => void;
  login: (email: string, password: string) => Promise<User>;
  logout: () => void;
}

// Crear el contexto con un valor predeterminado
export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) setIsAuthenticated(true);
  }, []);

  const login = async (email: string, password: string): Promise<User> => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/login`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({ email, password }),
      }
    );
    const body = await response.json();
    if (!response.ok) throw new Error(body.message || "Error de autenticación");

    localStorage.setItem("token", body.data.jwt);
    setIsAuthenticated(true);
    setUser(body.data.user);
    return body.data.user;
  };

  const setAuthenticated = (authStatus: boolean) => {
    setIsAuthenticated(authStatus); // Actualiza el estado
  };

  const logout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    setUser(undefined);
  };

  return (
    <AuthContext.Provider
      value={{ user, isAuthenticated, setAuthenticated, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
}
export default AuthContext;
