import { ThemeProvider } from "@mui/material/styles";
import theme from "../../styles/theme";
import { DocumentUploader } from "../../components/DocumentUploader/DocumentUploader";

interface IUploadDocumentsProps {
  userId: string;
  documentId: string;
  userType: "student" | "tutor";
}

function UploadDocuments({
  userId,
  documentId,
  userType,
}: IUploadDocumentsProps) {
  return (
    <ThemeProvider theme={theme}>
      <DocumentUploader
        userId={userId}
        documentId={documentId}
        userType={userType}
      />
    </ThemeProvider>
  );
}

export default UploadDocuments;
