import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import {
  Grid,
  Box,
  Toolbar,
  Container,
  CssBaseline,
  useMediaQuery,
} from "@mui/material";
import Copyright from "../Copyright/Copyright";
import NavigationTop from "./NavigationTop";
import NavigationSide from "./NavigationSide";
import { Theme } from "@mui/material/styles";

interface NavigationTopProps {
  open?: boolean;
  toggleDrawer?: () => void;
}

function Navigation(props: NavigationTopProps) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleToggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <NavigationTop open={drawerOpen} toggleDrawer={handleToggleDrawer} />

      <NavigationSide open={drawerOpen} toggleDrawer={handleToggleDrawer} />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3} sx={{ mb: 4 }}>
            <Outlet />
          </Grid>
          <Copyright />
        </Container>
      </Box>
    </Box>
  );
}

export default Navigation;
