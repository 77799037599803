import { Box, Container, Grid, TextField, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Leads } from "../../interfaces/lead";

const theme = createTheme({
  palette: {
    primary: {
      main: "#e30613",
    },
  },
});

interface ILeadProps {
  leadData: Leads;
  setLeadData: any;
}

function TutorFormTwo(props: ILeadProps) {
  const { leadData, setLeadData } = props;

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            p: 3,
          }}
        >
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography align="left" sx={{ mb: 1 }}>
                  Introduza la información del Segundo Tutor (Opcional):
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoFocus
                  id="first_name"
                  name="first_name"
                  label="Nombre"
                  required
                  fullWidth
                  value={leadData.tutors[1].first_name}
                  onChange={(e) =>
                    setLeadData({
                      ...leadData,
                      tutors: [
                        leadData.tutors[0],
                        {
                          ...leadData.tutors[1],
                          first_name: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="last_name"
                  name="last_name"
                  label="Apellido"
                  required
                  fullWidth
                  value={leadData.tutors[1].last_name}
                  onChange={(e) =>
                    setLeadData({
                      ...leadData,
                      tutors: [
                        leadData.tutors[0],
                        {
                          ...leadData.tutors[1],
                          last_name: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="company"
                  name="company"
                  label="Empresa"
                  required
                  fullWidth
                  value={leadData.tutors[1].company}
                  onChange={(e) =>
                    setLeadData({
                      ...leadData,
                      tutors: [
                        leadData.tutors[0],
                        {
                          ...leadData.tutors[1],
                          company: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="company_role"
                  name="company_role"
                  label="Puesto"
                  required
                  fullWidth
                  value={leadData.tutors[1].company_role}
                  onChange={(e) =>
                    setLeadData({
                      ...leadData,
                      tutors: [
                        leadData.tutors[0],
                        {
                          ...leadData.tutors[1],
                          company_role: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="phone"
                  name="phone"
                  label="Número"
                  required
                  fullWidth
                  value={leadData.tutors[1].phone}
                  onChange={(e) =>
                    setLeadData({
                      ...leadData,
                      tutors: [
                        leadData.tutors[0],
                        {
                          ...leadData.tutors[1],
                          phone: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  name="email"
                  label="correo"
                  required
                  fullWidth
                  value={leadData.tutors[1].email}
                  onChange={(e) =>
                    setLeadData({
                      ...leadData,
                      tutors: [
                        leadData.tutors[0],
                        {
                          ...leadData.tutors[1],
                          email: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default TutorFormTwo;
