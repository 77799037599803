import { Fragment, useState } from "react";
import { ListItemButton, ListItemText, List, Collapse } from "@mui/material";
import { Tutor } from "../../interfaces/tutor";
import UserDocumentSelect from "./UserDocumentSelect";

interface IUserAccountProps {
  tutors?: Tutor[];
}

function TutorList({ tutors }: IUserAccountProps) {
  const [selectedTutorId, setSelectedTutorId] = useState<string | null>(null);

  const handleTutorClick = (id: string) => {
    setSelectedTutorId((prevSelectedId) => (prevSelectedId === id ? null : id));
  };

  const renderTutors = () => {
    if (!tutors) {
      return <div>Cargando Tutores...</div>;
    }

    return tutors.map(({ id, first_name, last_name }) => (
      <Fragment key={id}>
        <ListItemButton
          selected={id === selectedTutorId}
          onClick={() => id && handleTutorClick(id)}
        >
          <ListItemText>{`${first_name} ${last_name}`}</ListItemText>
        </ListItemButton>
        <Collapse in={selectedTutorId === id} unmountOnExit>
          <UserDocumentSelect tutorId={id} />
        </Collapse>
      </Fragment>
    ));
  };

  return <Fragment>{renderTutors()}</Fragment>;
}

export default TutorList;
