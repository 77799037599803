import React, { ChangeEvent } from "react";
import {
  Grid,
  TextField,
  Button,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Student } from "../../interfaces/student";
import categoryMap from "../../interfaces/category-map";

interface EditStudentTableProps {
  student: Student;
  setStudent: React.Dispatch<React.SetStateAction<Student>>;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  handleSubmit: (updatedStudent: Student) => Promise<void>;
  studentPhotoPath: string | null;
}

function EditStudentTable({
  student,
  setStudent,
  handleChange,
  isLoading,
  handleSubmit,
  studentPhotoPath,
}: EditStudentTableProps) {
  const localHandleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setStudent((prev) => ({ ...prev, [name]: value }));
  };

  const handleWeightChange = (event: SelectChangeEvent<string>) => {
    setStudent((prevState) => ({
      ...prevState,
      weight: event.target.value,
    }));
  };

  const displayWeightValue = () => {
    const weightFloat = parseFloat(student.weight);
    return Number.isInteger(weightFloat)
      ? weightFloat.toString()
      : student.weight;
  };

  const handleLocalSubmit = async () => {
    await handleSubmit(student);
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      spacing={2}
      xs={12}
      sx={{ width: "100%", margin: 0 }}
    >
      <Grid item xs={12} md={12}>
        {studentPhotoPath ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
          >
            <Box
              component="img"
              src={studentPhotoPath}
              alt="Foto del alumno"
              sx={{
                maxWidth: "100%",
                maxHeight: "200px",
                display: "block",
                margin: "0 auto",
              }}
            />
          </Box>
        ) : (
          <Typography>No hay foto del alumno.</Typography>
        )}
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          label="First Name"
          name="first_name"
          variant="filled"
          fullWidth
          value={student?.first_name}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Last Name"
          name="last_name"
          variant="filled"
          fullWidth
          value={student?.last_name}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Birthdate"
          name="birthdate"
          variant="filled"
          fullWidth
          value={student?.birthdate}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl variant="filled" fullWidth>
          <InputLabel shrink={true}>
            Peso (Es importante para definir la categoría)
          </InputLabel>
          <Select
            name="weight"
            value={displayWeightValue()}
            onChange={handleWeightChange}
          >
            {Array.from({ length: 150 }, (_, i) => i + 1).map((weight) => (
              <MenuItem key={weight} value={weight.toString()}>
                {weight}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          select
          label="Categoría"
          name="category_id"
          variant="filled"
          fullWidth
          value={student?.category_id || ""}
          onChange={handleChange}
          SelectProps={{
            native: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        >
          {Object.entries(categoryMap).map(([id, name]) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          label="School"
          name="school"
          variant="filled"
          fullWidth
          value={student?.school}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="School Grade"
          name="school_grade"
          variant="filled"
          fullWidth
          value={student?.school_grade}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="School Level"
          name="school_level"
          variant="filled"
          fullWidth
          value={student?.school_level}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Address"
          name="address"
          variant="filled"
          fullWidth
          value={student?.address}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "center", mb: 2, mt: 2 }}>
        {isLoading ? (
          <LoadingButton loading variant="contained">
            Guardar
          </LoadingButton>
        ) : (
          <Button variant="contained" onClick={handleLocalSubmit}>
            Guardar
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

export default EditStudentTable;
