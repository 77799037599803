import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import { Document } from "../../interfaces/document";
import PendingIcon from "@mui/icons-material/Pending";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/system";

interface ShowUplDocumentsProps {
  documentList: Document[];
  onDelete: (docId: string) => void;
  onDownload: (docId: string) => void;
}

const GreenTaskAltIcon = styled(TaskAltIcon)({
  color: "green",
});

const RedHighlightOffIcon = styled(HighlightOffIcon)({
  color: "red",
});

export function ShowUplDocuments({
  documentList,
  onDelete,
  onDownload,
}: ShowUplDocumentsProps) {
  const [anchorEl, setAnchorEl] = useState<{
    [id: string]: HTMLElement | null;
  }>({});

  const openMenu = Boolean(anchorEl);

  function getIconByStatus(statusId: number | undefined) {
    switch (statusId) {
      case 1:
        return <PendingIcon />;
      case 2:
        return <GreenTaskAltIcon />;
      case 3:
        return <RedHighlightOffIcon />;
      default:
        return <PendingIcon />;
    }
  }

  const handleMenu = (id: string) => (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl((prevState) => ({ ...prevState, [id]: event.currentTarget }));
  };

  const handleCloseMenu = (id: string) => {
    setAnchorEl((prevState) => ({ ...prevState, [id]: null }));
  };

  const onDownloadHandler = (docId: string) => {
    onDownload(docId);
    handleCloseMenu(docId);
  };

  const onDeleteHandler = (docId: string) => {
    onDelete(docId);
    handleCloseMenu(docId);
  };
  return (
    <List>
      {documentList.map((document) => (
        <ListItem key={document.id} divider={true}>
          <ListItemIcon>
            {getIconByStatus(document.document_status_id)}
          </ListItemIcon>
          <ListItemText
            primary={document.title}
            secondary={document.document_rejected_reason?.title}
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="options"
              onClick={handleMenu(document.id.toString())}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl[document.id.toString()]}
              open={Boolean(anchorEl[document.id.toString()])}
              onClose={() => handleCloseMenu(document.id.toString())}
            >
              <MenuItem
                onClick={() => onDownloadHandler(document.id.toString())}
              >
                <VisibilityIcon sx={{ mr: 1 }} />
                Ver
              </MenuItem>
              <MenuItem onClick={() => onDeleteHandler(document.id.toString())}>
                <DeleteForeverIcon sx={{ mr: 1 }} />
                Borrar
              </MenuItem>
            </Menu>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
}
