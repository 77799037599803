import { ChangeEvent } from "react";
import { Tutor } from "../../interfaces/tutor";
import { TextField, Grid, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

interface EditTutorTableProps {
  tutor: Tutor;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  handleSubmit: () => Promise<void>;
}

function EditTutorTable({
  tutor,
  handleChange,
  isLoading,
  handleSubmit,
}: EditTutorTableProps) {
  return (
    <Grid
      container
      spacing={2}
      xs={12}
      alignItems="center"
      justifyContent="center"
      sx={{ width: "100%", margin: 0 }}
    >
      <Grid item xs={12} md={6}>
        <TextField
          label="First Name"
          name="first_name"
          variant="filled"
          fullWidth
          value={tutor?.first_name}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Last Name"
          name="last_name"
          variant="filled"
          fullWidth
          value={tutor?.last_name}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Company"
          name="company"
          variant="filled"
          fullWidth
          value={tutor?.company}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Company Role"
          name="company_role"
          variant="filled"
          fullWidth
          value={tutor?.company_role}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Phone"
          name="phone"
          variant="filled"
          fullWidth
          value={tutor?.phone}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Email"
          name="email"
          variant="filled"
          fullWidth
          value={tutor?.email}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "center", mb: 2, mt: 2 }}>
        {isLoading ? (
          <LoadingButton loading variant="contained">
            Guardar
          </LoadingButton>
        ) : (
          <Button variant="contained" onClick={handleSubmit}>
            Guardar
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

export default EditTutorTable;
