import React from "react";
import { useNavigate } from "react-router";
import { Grid, Typography, Button, Divider } from "@mui/material";
import { Student } from "../../interfaces/student";
import { Tutor } from "../../interfaces/tutor";
import StudentForm from "../../components/Layouts/Forms/StudentForm";
import TutorForm from "../../components/Layouts/Forms/TutorForm";

interface IUserAccountProps {
  students: Student[];
  tutors: Tutor[];
  userType: "student" | "tutor";
}

export default function AccountInfo(props: IUserAccountProps) {
  const tutors = props.userType === "tutor" ? [...(props.tutors ?? [])] : [];
  const students =
    props.userType === "student" ? [...(props.students ?? [])] : [];

  const navigate = useNavigate();

  return (
    <React.Fragment>
      {tutors.map((tutor) => (
        <React.Fragment key={tutor.id}>
          <Grid item xs={12} sx={{ mb: 2, mt: 2 }}>
            <Typography variant="h5" gutterBottom component="div">
              Tutor: {`${tutor.first_name} ${tutor.last_name}`}
            </Typography>
          </Grid>
          <TutorForm
            tutorData={tutor}
            setTutorData={() => {}}
            disabled={true}
          />
          <Divider sx={{ mb: 4 }} />
        </React.Fragment>
      ))}
      {students.map((student, index) => (
        <React.Fragment key={student.id}>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Typography variant="h5" gutterBottom component="div">
              Jugador: {`${student.first_name} ${student.last_name}`}
            </Typography>
          </Grid>
          <StudentForm
            studentData={student}
            setStudentData={() => {}}
            disabled={true}
            showCategoryField={true}
          />
          <Divider sx={{ mb: 4 }} />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}
