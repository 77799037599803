import { ThemeProvider } from "@mui/material/styles";
import theme from "../../../styles/theme";
import { DocumentUploader } from "../../../components/DocumentUploader/DocumentUploader";
import axiosInstance from "../../../api/axiosInstance";

interface IUploadDocumentsProps {
  userId: string;
  documentId: string;
  userType: "student" | "tutor";
}

function UploadPhoto({ userId, documentId, userType }: IUploadDocumentsProps) {
  const deleteCurrentDocument = async () => {
    const deleteUrl = `${process.env.REACT_APP_GET_STUDENT_DOCUMENTS}/${userId}/document/${documentId}`;

    try {
      await axiosInstance.delete(deleteUrl);
      console.log("Documento eliminado exitosamente");
    } catch (error) {
      console.error("Hubo un error al eliminar el documento", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <DocumentUploader
        userId={userId}
        documentId={documentId}
        userType={userType}
      />
    </ThemeProvider>
  );
}

export default UploadPhoto;
