import React, { useState, useEffect } from "react";
import { User } from "../interfaces/user";
import { getAuthUser } from "../api/queries";
interface UserContextData {
  user: User | null;
  setUser: (user: User) => void;
}

const UserContext = React.createContext<UserContextData>({} as UserContextData);

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<User | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const user = await getAuthUser();
          setState(user);
        } catch (error) {
          console.error("Error fetching user data:", error);
          localStorage.removeItem("token");
          setState(null);
        }
      }
    };

    fetchUserData();
  }, []);

  const setUser = (user: User | null) => setState(user);
  return (
    <UserContext.Provider value={{ user: state, setUser }}>
      {children}
    </UserContext.Provider>
  );
}

export default UserContext;
