import React from "react";
import ContentLayout from "../../components/Layouts/ContentLayout/ContentLayout";
import ChangePasswordForm from "./PasswordForm";

function Settings() {
  return (
    <ContentLayout title="Ajustes">
      <ChangePasswordForm />
    </ContentLayout>
  );
}

export default Settings;
