export const tabs = [
  {
    title: "Cuenta",
    description: {
      subTitle: null,
      image: null,
      steps: [
        {
          title: "Paso 1",
          text: "Accede al menú de la izquierda y selecciona 'Cuenta' o haz clic en el ícono de la persona en el recuadro.",
          image: null,
        },
        {
          title: "Paso 2",
          text: "Encontrarás cuatro botones: 'Información', para visualizar los detalles del usuario; 'Editar', para modificar los datos del usuario; 'Nuevo', para añadir un nuevo jugador o tutor; y 'Foto', para subir la imagen de los estudiantes. Utiliza el botón 'Mostrar Jugador' para alternar la visualización entre tutores y jugadores. En dispositivos móviles, aparecerá un ícono de una pelota para mostrar los jugadores y un ícono de una persona para los tutores.",
          image: null,
        },
      ],
    },
  },

  {
    title: "Pagos",
    description: {
      subTitle: null,
      image: null,
      steps: [
        {
          title: "Paso 1",
          text: "Dirígete al menú de la izquierda y selecciona 'Pagos' o el ícono de la tarjeta.",
          image: null,
        },
        {
          title: "Paso 2",
          text: "Escoge al jugador correspondiente.",
          image: null,
        },
        {
          title: "Paso 3",
          text: "Elige el tipo de documento que deseas subir.",
          image: null,
        },
        {
          title: "Paso 4",
          text: "Selecciona el documento específico que deseas cargar.",
          image: null,
        },
        {
          title: "Paso 5",
          text: "Sube el documento seleccionado.",
          image: null,
        },
      ],
    },
  },
  {
    title: "Documentos",
    description: {
      subTitle: null,
      image: null,
      steps: [
        {
          title: "Paso 1",
          text: "Accede al menú de la izquierda y selecciona 'Documentos' o el ícono del documento.",
          image: null,
        },
        {
          title: "Paso 2",
          text: "Elige al jugador o tutor correspondiente.",
          image: null,
        },
        {
          title: "Paso 3",
          text: "Selecciona el tipo de documento que desees subir.",
          image: null,
        },
        {
          title: "Paso 4",
          text: "Escoge el documento específico que quieras cargar.",
          image: null,
        },
        {
          title: "Paso 5",
          text: "Procede a subir el documento seleccionado.",
          image: null,
        },
      ],
    },
  },
];
