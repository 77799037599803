import axiosInstance from "../../api/axiosInstance";
import { useState } from "react";
import { Box, Button, Container, Paper, Grid } from "@mui/material";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../styles/theme";
import SignUpForm from "./SingUpForm";
import Copyright from "../../components/Copyright/Copyright";
import Image from "../../components/Image/Image";
import Logo from "../../assets/redsLogo.png";

function AdminSignUp() {
  const { enqueueSnackbar } = useSnackbar();
  const [signUpData, setSignUpData] = useState({
    email: "",
    password: "",
    passConfirmation: "",
  });

  const redAdminSignUp = process.env.REACT_APP_ADMIN;

  function handleSignUpError(error: any) {
    let errorMessage = "Ocurrió un error al intentar registrarse";

    if (error.response) {
      const { data, status } = error.response;

      if (status === 422) {
        // handle validation errors
        errorMessage = "Por favor verifica los datos ingresados";
      } else if (status === 500) {
        // handle server errors
        errorMessage =
          "Ocurrió un error en el servidor, por favor intenta de nuevo más tarde";
      } else {
        // handle other errors
        errorMessage = data.message || "Ocurrió un error inesperado";
      }
    }

    enqueueSnackbar(errorMessage, { variant: "error" });
  }

  //Post Request
  const singUpRequest = async () => {
    const { email, password, passConfirmation } = signUpData;

    // Verificar si el correo y la contraseña están presentes
    if (!email || !password || !passConfirmation) {
      enqueueSnackbar(
        "Por favor proporciona un correo electrónico y una contraseña",
        { variant: "error" }
      );
      return false;
    }

    // Verificar si las contraseñas son iguales antes de enviar la solicitud
    if (password !== passConfirmation) {
      enqueueSnackbar("Las contraseñas son diferentes", { variant: "error" });
      return false;
    }

    const dataToSend = { email, password }; // only include email and password

    try {
      const response = await axiosInstance.post(
        `${redAdminSignUp}`,
        dataToSend
      );
      console.log(dataToSend); // should now include email, password and is_admin
      console.log(response.data);
      return true;
    } catch (err) {
      handleSignUpError(err);
      console.log(dataToSend); // should now include email, password and is_admin
      console.log(err);
      return false;
    }
  };

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isSignUpSuccessful = await singUpRequest();
    if (isSignUpSuccessful) {
      navigate("/");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid item xs={12} sx={{ height: "100%" }}>
        <Container component="main" maxWidth="xs">
          <Paper
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              p: 3,
            }}
          >
            <Box sx={{ width: 1 / 2 }}>
              <Image src={Logo} alt="Rojos Logo" />
            </Box>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <SignUpForm
                signUpData={signUpData}
                setSignUpData={setSignUpData}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Registrar Administrador
              </Button>
            </Box>
          </Paper>
        </Container>
      </Grid>
    </ThemeProvider>
  );
}

export default AdminSignUp;
