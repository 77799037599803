import {
  Grid,
  Paper,
  Typography,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { ReactNode } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../../styles/theme";

interface ContentLayoutProps {
  children: ReactNode;
  title?: string;
  paperStyles?: object;
  titleStyles?: object;
  showDivider?: boolean;
}

function ContentLayout({
  children,
  title,
  paperStyles,
  titleStyles,
  showDivider = false,
}: ContentLayoutProps) {
  const currentTheme = useTheme();
  const isMobile = useMediaQuery(currentTheme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(currentTheme.breakpoints.down("md"));

  return (
    <ThemeProvider theme={theme}>
      <Grid item container spacing={2} sx={{ height: "100%" }}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 4,
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              borderRadius: "20px",
              overflowX: "hidden",
              ...paperStyles,
            }}
          >
            {title && (
              <>
                <Typography
                  variant={isMobile ? "h5" : isMedium ? "h4" : "h3"}
                  sx={{
                    mb: 3,
                    textAlign: isMobile ? "center" : "inherit",
                    ...titleStyles,
                  }}
                >
                  {title}
                </Typography>
                {showDivider && <Divider sx={{ mb: 2 }} />}
              </>
            )}
            {children}
          </Paper>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default ContentLayout;
