import axiosInstance from "../../api/axiosInstance";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { Tutor } from "../../interfaces/tutor";
import { Student } from "../../interfaces/student";

const requestStudent = process.env.REACT_APP_STUDENT || "";
const requestTutor = process.env.REACT_APP_TUTOR || "";
const getStudents = process.env.REACT_APP_GET_ALL_STUDENTS;
const getTutors = process.env.REACT_APP_GET_ALL_TUTORS;

// Create

export const useCreateTutor = (
  tutorData: Tutor | null,
  existingTutors: Tutor[]
) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const createTutor = async (
    tutorData: Tutor | null,
    existingTutors: Tutor[]
  ) => {
    if (tutorData && Object.values(tutorData).every((value) => value !== "")) {
      // Verificar si el tutor ya existe
      const tutorExists = existingTutors.some(
        (existingTutor) =>
          existingTutor.first_name === tutorData.first_name &&
          existingTutor.last_name === tutorData.last_name
      );

      if (tutorExists) {
        enqueueSnackbar("Un estudiante con el mismo nombre ya existe", {
          variant: "error",
        });
        setLoading(false);
        return;
      }
      try {
        setLoading(true);
        const response = await axiosInstance.post(requestTutor, tutorData);
        console.log(response.data);
        console.log(tutorData);
        enqueueSnackbar("Tutor creado exitosamente", { variant: "success" });
      } catch (err) {
        console.log(err);
        console.log(tutorData);
        enqueueSnackbar("Hubo un error al crear el tutor", {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    } else {
      enqueueSnackbar("Por favor llene todos los campos", {
        variant: "error",
      });
    }
  };
  return { loading, error, createTutor };
};

export const useCreateStudent = (
  studentData: Student | null,
  existingStudents: Student[]
) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const createStudent = async (
    studentData: Student | null,
    existingStudents: Student[]
  ) => {
    if (
      studentData &&
      Object.values(studentData).every((value) => value !== "")
    ) {
      // Verificar si el estudiante ya existe
      const studentExists = existingStudents.some(
        (existingStudent) =>
          existingStudent.first_name === studentData.first_name &&
          existingStudent.last_name === studentData.last_name
      );

      if (studentExists) {
        enqueueSnackbar("Un estudiante con el mismo nombre ya existe", {
          variant: "error",
        });
        setLoading(false);
        return;
      }
      try {
        setLoading(true);
        const response = await axiosInstance.post(requestStudent, studentData);
        console.log(response.data);
        console.log(studentData);
        enqueueSnackbar("Jugador creado exitosamente", {
          variant: "success",
        });
      } catch (err) {
        console.log(err);
        console.log(studentData);
        enqueueSnackbar("Hubo un error al crear el jugador", {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    } else {
      enqueueSnackbar("Por favor llene todos los campos", {
        variant: "error",
      });
    }
  };

  return { loading, error, createStudent };
};

// Edit

export const useTutorPut = (
  tutor: Tutor[] | null,
  setTutor: React.Dispatch<React.SetStateAction<Tutor[] | null>>
) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const updateTutor = async (tutorId: string) => {
    const selectedTutor = tutor?.find((t) => t.id === tutorId);

    if (selectedTutor) {
      setLoading(true);
      try {
        console.log("Tutor data sent:", selectedTutor);
        const response = await axiosInstance.put(
          `${requestTutor}/${tutorId}`,
          selectedTutor
        );
        console.log(response.data);
        setTutor((prevTutors) => {
          const updatedTutors = (prevTutors ?? []).map((t) =>
            t.id === tutorId ? selectedTutor : t
          );
          return updatedTutors;
        });
        enqueueSnackbar("Tutor editado exitosamente", { variant: "success" });
      } catch (err) {
        console.log(err);
        enqueueSnackbar("hubo un error al editar el tutor", {
          variant: "error",
        });
      }
      setLoading(false);
    } else {
      console.warn(`Tutor with ID ${tutorId} not found`);
    }
  };

  return { loading, put: updateTutor };
};

export const useStudentPut = (
  student: Student[] | null,
  setStudent: React.Dispatch<React.SetStateAction<Student[] | null>>
) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const updateStudent = async (studentId: string) => {
    const selectedStudent = student?.find((s) => s.id === studentId);
    if (selectedStudent) {
      setLoading(true);
      try {
        console.log("Student data sent:", selectedStudent);
        const response = await axiosInstance.put(
          `${requestStudent}/${studentId}`,
          selectedStudent
        );
        console.log(response.data);
        setStudent((prevStudents) => {
          const updatedStudents = (prevStudents ?? []).map((s) =>
            s.id === studentId ? selectedStudent : s
          );
          return updatedStudents;
        });
        enqueueSnackbar("Jugador editado exitosamente", {
          variant: "success",
        });
      } catch (err) {
        console.log(err);
        enqueueSnackbar("hubo un error al editar el jugador", {
          variant: "error",
        });
      }
      setLoading(false);
    } else {
      console.warn(`Student with ID ${studentId} not found`);
    }
  };

  return { loading, put: updateStudent };
};

// Delete

export const useTutorDelete = (
  setTutor: React.Dispatch<React.SetStateAction<Tutor[] | null>>
) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const deleteTutor = async (tutorId: string) => {
    setLoading(true);
    try {
      const response = await axiosInstance.delete(`${requestTutor}/${tutorId}`);
      console.log(response.data);
      setTutor((prevTutors: Tutor[] | null) =>
        prevTutors ? prevTutors.filter((t) => t.id !== tutorId) : null
      );
      enqueueSnackbar("Tutor eliminado exitosamente", { variant: "success" });
    } catch (err) {
      console.log(err);
      enqueueSnackbar("hubo un error al eliminar el tutor", {
        variant: "error",
      });
    }
    setLoading(false);
  };

  return { loading, remove: deleteTutor };
};

export const useStudentDelete = (
  setStudent: React.Dispatch<React.SetStateAction<Student[] | null>>
) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const deleteStudent = async (studentId: string) => {
    setLoading(true);
    try {
      const response = await axiosInstance.delete(
        `${requestStudent}/${studentId}`
      );
      console.log(response.data);
      setStudent((prevStudents: Student[] | null) =>
        prevStudents ? prevStudents.filter((s) => s.id !== studentId) : null
      );
      enqueueSnackbar("jugador eliminado exitosamente", {
        variant: "success",
      });
    } catch (err) {
      console.log(err);
      enqueueSnackbar("hubo un error al eliminar el jugador", {
        variant: "error",
      });
    }
    setLoading(false);
  };

  return { loading, remove: deleteStudent };
};

// Get User Tutors a Students

export const useGetUserStudentnTutor = () => {
  const [students, setStudents] = useState([]);
  const [tutors, setTutors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    Promise.all([
      axiosInstance.get(`${getStudents}`),
      axiosInstance.get(`${getTutors}`),
    ])
      .then(([studentResponse, tutorResponse]) => {
        console.log("Student response data:", studentResponse.data);
        console.log("Tutor response data:", tutorResponse.data);

        setStudents(studentResponse.data.data.students);
        setTutors(tutorResponse.data.data.tutors);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsError(true);
        setIsLoading(false);
      });
  }, []);

  return { students, tutors, isLoading, isError };
};

export const useGetAllStudents = () => {
  const [students, setStudents] = useState<Student[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    axiosInstance
      .get(`${getStudents}`)
      .then((studentResponse) => {
        console.log("Student response data:", studentResponse.data);
        setStudents(studentResponse.data.data.students);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsError(true);
        setIsLoading(false);
      });
  }, []);

  return { students, isLoading, isError };
};

export const usePaymentNotification = () => {
  const [shouldNotify, setShouldNotify] = useState(false);

  const shouldShowPaymentNotification = (
    lastPaymentDate: string | null
  ): boolean => {
    if (!lastPaymentDate) return false;

    const currentDate = new Date();
    const paymentDate = new Date(lastPaymentDate);
    const timeDifference = currentDate.getTime() - paymentDate.getTime();
    const dayDifference = timeDifference / (1000 * 3600 * 24);

    const notify = dayDifference >= 28 && dayDifference <= 32;
    setShouldNotify(notify);
    return notify;
  };

  return { shouldNotify, shouldShowPaymentNotification };
};

export const useGeStudentsAndPayment = () => {
  const [students, setStudents] = useState<Student[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const { shouldShowPaymentNotification } = usePaymentNotification();

  useEffect(() => {
    axiosInstance
      .get(`${getStudents}`)
      .then((studentResponse) => {
        console.log("Student response data:", studentResponse.data);

        const processedStudents = studentResponse.data.data.students.map(
          (student: any) => {
            const shouldShowNotification = shouldShowPaymentNotification(
              student.last_payment
            );
            return {
              ...student,
              shouldShowNotification,
            };
          }
        );

        setStudents(processedStudents);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsError(true);
        setIsLoading(false);
      });
  }, []);

  return { students, isLoading, isError };
};
