import { useGetUserStudentnTutor } from "../../components/apiRequest/StudentAndTutorRequests";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../styles/theme";
import AccountInfo from "./AccountInfo";
import EmptyAccount from "./EmptyAccount";
import AccountFormEdit from "./AccountFormEdit/AccountFormEdit";
import AccountFormNew from "./AccountFormNew";
import AccountPhoto from "./AccountPhoto/AccountPhoto";
import { useState } from "react";
import AccountContainer from "../../components/Layouts/TabbedContainer/Containers/AccountContainer";

function Account() {
  const { students, tutors, isLoading, isError } = useGetUserStudentnTutor();
  const [activeTab, setActiveTab] = useState("info");
  const navigate = useNavigate();
  const [userType, setUserType] = useState<"tutor" | "student">("tutor");

  const handleTabClick = (tab: string) => {
    console.log("Clicked tab:", tab);
    setActiveTab(tab);
    console.log("Updated activeTab to:", activeTab);

    switch (tab) {
      case "info":
        navigate("/cuenta");
        break;
      case "edit":
        navigate("/cuenta/editar");
        break;
      case "new":
        navigate("/cuenta/nuevo");
        break;
      case "photo":
        navigate("/cuenta/fotos");
        break;

      default:
        navigate("/cuenta");
    }
  };

  const handleTypeChange = (type: "tutor" | "student") => {
    setUserType(type);
  };

  const tabs = [
    { value: "info", label: "Información" },
    { value: "edit", label: "Editar" },
    { value: "new", label: "Nuevo" },
    { value: "photo", label: "Fotos" },
  ];

  return (
    <ThemeProvider theme={theme}>
      <AccountContainer
        activeTab={activeTab}
        onTabClick={handleTabClick}
        onTypeChange={handleTypeChange}
        tabs={tabs}
      >
        <Routes>
          {isError ? (
            <Route path="*" element={<div>Error al cargar los datos</div>} />
          ) : (
            <>
              <Route path="/empty-account" element={<EmptyAccount />} />
              <Route
                path="/editar"
                element={<AccountFormEdit userType={userType} />}
              />
              <Route path="/nuevo" element={<AccountFormNew />} />
              <Route path="/fotos" element={<AccountPhoto />} />

              <Route
                path="/"
                element={
                  isLoading ? (
                    <div>Cargando...</div>
                  ) : (
                    <>
                      {students.length || tutors.length ? (
                        <AccountInfo
                          userType={userType}
                          students={students}
                          tutors={tutors}
                        />
                      ) : (
                        <Navigate to="/cuenta/empty-account" replace />
                      )}
                    </>
                  )
                }
              />
            </>
          )}
        </Routes>
      </AccountContainer>
    </ThemeProvider>
  );
}

export default Account;
