import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import { Typography, IconButton, Toolbar, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { AuthContext } from "../../context/AuthContext";
import PaymentNotification from "../Notifications/PaymentNotifcation";
import { User } from "../../interfaces/user";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

interface NavigationTopProps {
  open?: boolean;
  toggleDrawer?: () => void;
}

interface AuthContextData {
  user?: User;
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<User>;
  logout: () => void;
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      top: "-2000px",
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function NavigationTop(props: NavigationTopProps) {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  if (!auth.isAuthenticated) return null;
  const handleClick = () => {
    auth.logout();
    navigate("/");
  };

  return (
    <AppBar position="absolute" open={props.open}>
      <Toolbar
        sx={{
          pr: "24px",
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={props.toggleDrawer}
          sx={{
            marginRight: "36px",
            ...(props.open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>

        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          Rojos CDMX / San Luis Potosí
        </Typography>
        {auth.user && !auth.user.is_admin && <PaymentNotification />}

        <Button variant="outlined" color="inherit" onClick={handleClick}>
          Salir
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default NavigationTop;
