import { Grid } from "@mui/material";
import TabsSelector from "../TabsSelector/GeneralTabs";
import ContentLayout from "../../ContentLayout/ContentLayout";
interface GeneralContainerProps {
  activeTab: string;
  onTabClick: (tab: string) => void;
  tabs: { label: string; value: string }[];
  children: React.ReactNode;
}

const GeneralContainer: React.FC<GeneralContainerProps> = ({
  activeTab,
  onTabClick,
  tabs,
  children,
}) => {
  return (
    <ContentLayout paperStyles={{ height: "100%" }}>
      <Grid>
        <TabsSelector
          activeTab={activeTab}
          onTabClick={onTabClick}
          tabs={tabs}
        />
        {children}
      </Grid>
    </ContentLayout>
  );
};

export default GeneralContainer;
