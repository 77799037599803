import { ChangeEvent, useState } from "react";
import axiosInstance from "../../api/axiosInstance";
import { useSnackbar } from "notistack";
import {
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import { Fragment } from "react";
import { Document } from "../../interfaces/document";
import { ShowUplDocuments } from "../../components/ShowUplDocuments/ShowUplDocuments";

interface IUploadedDocumentsProps {
  userId: string;
  documentList: Document[];
  error: boolean;
  errorMessage: string;
}

function ErrorDisplay({ errorMessage }: { errorMessage: string }) {
  return (
    <Box display="flex" justifyContent="center">
      <Typography variant="subtitle1">{errorMessage}</Typography>
    </Box>
  );
}

function NoDocumentsDisplay() {
  return (
    <Box display="flex" justifyContent="center">
      <Typography variant="subtitle1">
        No existen documentos subidos por el momento...
      </Typography>
    </Box>
  );
}

function UploadedPayments(props: IUploadedDocumentsProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { error, errorMessage, documentList } = props;

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

  // Manejadores para los cambios en los selectores
  const handleYearChange = (event: SelectChangeEvent<number>) => {
    setSelectedYear(parseInt(event.target.value as string));
  };

  const handleMonthChange = (event: SelectChangeEvent<number>) => {
    setSelectedMonth(parseInt(event.target.value as string));
  };

  const filteredDocumentList = documentList
    .filter((doc) => doc.document_id === 9)
    .filter((doc) => {
      if (doc.created_at) {
        const docDate = new Date(doc.created_at);
        return (
          docDate.getFullYear() === selectedYear &&
          docDate.getMonth() + 1 === selectedMonth
        );
      }
      return false;
    });

  const handleDelete = async (docId: string) => {
    const deleteUrl = `${process.env.REACT_APP_GET_STUDENT_DOCUMENTS}/${docId}`;

    try {
      await axiosInstance.delete(deleteUrl);
      enqueueSnackbar("Documento eliminado exitosamente", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Hubo un error al eliminar el documento", {
        variant: "error",
      });
    }
  };

  const handleDownload = (docId: string) => {
    console.log(`Downloading document with id ${docId}`);
    const document = documentList.find((doc) => doc.id.toString() === docId);
    if (document && document.file_path) {
      window.open(document.file_path, "_blank", "noopener noreferrer");
    }
  };

  // Generar los MenuItem para los años
  const yearItems = [];
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year <= currentYear + 10; year++) {
    yearItems.push(
      <MenuItem key={year} value={year}>
        {year}
      </MenuItem>
    );
  }
  // Generar los MenuItem para los meses
  const monthItems = [];
  for (let month = 1; month <= 12; month++) {
    monthItems.push(
      <MenuItem key={month} value={month}>
        {month}
      </MenuItem>
    );
  }

  return (
    <Fragment>
      <Box sx={{ mb: 2, display: "flex" }}>
        <FormControl variant="outlined">
          <InputLabel>Año</InputLabel>
          <Select value={selectedYear} onChange={handleYearChange} label="Año">
            {yearItems}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>Mes</InputLabel>
          <Select
            value={selectedMonth}
            onChange={handleMonthChange}
            label="Mes"
          >
            {monthItems}
          </Select>
        </FormControl>
      </Box>
      {error ? (
        <ErrorDisplay errorMessage={errorMessage} />
      ) : filteredDocumentList.length === 0 ? (
        <NoDocumentsDisplay />
      ) : (
        <ShowUplDocuments
          documentList={filteredDocumentList}
          onDelete={handleDelete}
          onDownload={handleDownload}
        />
      )}
    </Fragment>
  );
}

export default UploadedPayments;
