import { useSnackbar, SnackbarProps, OptionsObject } from "notistack";

//URLs
const delDoc = process.env.REACT_APP_GET_STUDENT_DOCUMENTS;
const putStudentDoc = process.env.REACT_APP_STUDENT_DOCUMENTS;

type EnqueueSnackbar = (message: string, options?: OptionsObject) => void;

export const handleApprove = async (
  id: string,
  axiosInstance: any, // Aquí considero axiosInstance como 'any' porque no tengo la definición exacta del tipo.
  enqueueSnackbar: EnqueueSnackbar
) => {
  try {
    await axiosInstance.put(`${putStudentDoc}/${id}`, {
      document_status_id: 2,
    });
    enqueueSnackbar("Se a editado exitosamente", { variant: "success" });
  } catch (error) {
    console.error(error);
    enqueueSnackbar("hubo un error al cambiar el estado", { variant: "error" });
  }
};

export const handleReject = async (
  id: string,
  axiosInstance: any,
  enqueueSnackbar: EnqueueSnackbar
) => {
  try {
    await axiosInstance.put(`${putStudentDoc}/${id}`, {
      document_status_id: 3,
    });
    enqueueSnackbar("Se a editado exitosamente", { variant: "success" });
  } catch (error) {
    console.error(error);
    enqueueSnackbar("hubo un error al cambiar el estado", { variant: "error" });
  }
};

export const handleDelete = async (
  id: string,
  axiosInstance: any,
  documents: any[],
  setDocuments: React.Dispatch<React.SetStateAction<any[]>>,
  enqueueSnackbar: EnqueueSnackbar
) => {
  try {
    await axiosInstance.delete(`${delDoc}/${id}`);
    setDocuments(documents.filter((document) => document.id !== id));
    enqueueSnackbar("Documento eliminado exitosamente", { variant: "success" });
  } catch (error) {
    console.error(error);
    enqueueSnackbar("Hubo un error al eliminar el documento", {
      variant: "error",
    });
  }
};

export const handleDownload = (id: string, documents: any[]) => {
  const document = documents.find((doc) => doc.id === id);
  if (document) {
    const filePath = document.file_path;
    window.open(filePath, "_blank", "noopener noreferrer");
  }
};
