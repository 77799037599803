import { Grid, TextField, Typography } from "@mui/material";
import { User } from "../../interfaces/user";

interface IAuthProps {
  signUpData: User;
  setSignUpData: any;
}

function SignUpForm(props: IAuthProps) {
  const { signUpData, setSignUpData } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>
          Introduce un correo para registrate a la plataforma:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="email"
          name="email"
          label="Correo Electrónico"
          required
          fullWidth
          value={signUpData.email}
          onChange={(e) =>
            setSignUpData({ ...signUpData, email: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="password"
          name="password"
          label="Contraseña"
          type="password"
          required
          fullWidth
          value={signUpData.password}
          onChange={(e) =>
            setSignUpData({ ...signUpData, password: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="passConfirmation"
          name="passConfirmation"
          label="Confirma tu contraseña"
          type="password"
          required
          fullWidth
          value={signUpData.passConfirmation}
          onChange={(e) =>
            setSignUpData({ ...signUpData, passConfirmation: e.target.value })
          }
        />
      </Grid>
    </Grid>
  );
}

export default SignUpForm;
