import { Typography, ListItem, ListItemText } from "@mui/material";

interface UserGuideProps {
  title?: string;
  text: string;
  image?: string;
}

function UserGuide({ title, text, image }: UserGuideProps) {
  return (
    <ListItem>
      <ListItemText>
        {title && (
          <Typography variant="subtitle1" align="justify">
            {title}
          </Typography>
        )}
        <Typography variant="body1" align="justify">
          {text}
        </Typography>
        {image && <img src={image} alt={title ?? text} />}
      </ListItemText>
    </ListItem>
  );
}

export default UserGuide;
