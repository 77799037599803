import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import CustomDatePicker from "../../DatePicker/CustomDatePicker";
import { Student } from "../../../interfaces/student";

interface StudentFormProps {
  studentData: Student;
  setStudentData: React.Dispatch<React.SetStateAction<Student>>;
  disabled?: boolean;
  showCategoryField?: boolean;
}

function StudentForm({
  studentData,
  setStudentData,
  disabled,
  showCategoryField = false,
}: StudentFormProps) {
  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = event.target;
    setStudentData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    if (name) {
      setStudentData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleWeightChange = (event: SelectChangeEvent<string>) => {
    setStudentData((prevState) => ({
      ...prevState,
      weight: event.target.value,
    }));
  };

  const displayWeightValue = () => {
    const weightFloat = parseFloat(studentData.weight);
    if (Number.isInteger(weightFloat)) {
      return weightFloat.toString();
    }
    return studentData.weight;
  };

  return (
    <Grid container item spacing={2} xs={12} sx={{ mb: 4 }}>
      <Grid item xs={12} md={6}>
        <TextField
          label="Nombre"
          name="first_name"
          variant="filled"
          disabled={disabled}
          fullWidth
          value={studentData.first_name}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true, // Esto hace que la etiqueta se mueva hacia arriba
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Apellido"
          name="last_name"
          variant="filled"
          disabled={disabled}
          fullWidth
          value={studentData.last_name}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomDatePicker
          label="Fecha de Nacimiento"
          value={studentData.birthdate}
          disabled={disabled}
          onChange={(dateString) => {
            setStudentData({ ...studentData, birthdate: dateString });
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl variant="filled" fullWidth>
          <InputLabel shrink={true}>
            {`Peso (Es importante para definir la categoría)`}
          </InputLabel>
          <Select
            name="weight"
            disabled={disabled}
            value={displayWeightValue()}
            onChange={handleWeightChange}
          >
            {Array.from({ length: 150 }, (_, i) => i + 1).map((weight) => (
              <MenuItem key={weight} value={weight.toString()}>
                {weight}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {showCategoryField && (
        <Grid item xs={12} md={6}>
          <TextField
            label="Categoría"
            name="Categoría"
            variant="filled"
            disabled={disabled}
            fullWidth
            value={studentData.category?.title}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <TextField
          label="Institución Educativa"
          name="school"
          variant="filled"
          disabled={disabled}
          fullWidth
          value={studentData.school}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl variant="filled" fullWidth>
          <InputLabel shrink={true}>Grado Escolar</InputLabel>
          <Select
            name="school_grade"
            disabled={disabled}
            value={studentData.school_grade}
            onChange={handleSelectChange}
          >
            <MenuItem value="Primero">Primero</MenuItem>
            <MenuItem value="Segundo">Segundo</MenuItem>
            <MenuItem value="Tercero">Tercero</MenuItem>
            <MenuItem value="Cuarto">Cuarto</MenuItem>
            <MenuItem value="Quinto">Quinto</MenuItem>
            <MenuItem value="Sexto">Sexto</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl variant="filled" fullWidth>
          <InputLabel shrink={true}>Escolaridad</InputLabel>
          <Select
            name="school_level"
            disabled={disabled}
            value={studentData.school_level}
            onChange={handleSelectChange}
          >
            <MenuItem value="Primaria">Primaria</MenuItem>
            <MenuItem value="Secundaria">Secundaria</MenuItem>
            <MenuItem value="Preparatoria">Preparatoria</MenuItem>
            <MenuItem value="Licenciatura">Licenciatura</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Domicilio"
          name="address"
          variant="filled"
          disabled={disabled}
          fullWidth
          value={studentData.address}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>
  );
}

export default StudentForm;
