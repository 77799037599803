interface Props {
  src: string
  alt: string
}

function Image(props: Props) {
  return (
    <img
      src={props.src}
      alt={props.alt}
      style={{
        maxWidth: '100%',
        height: 'auto',
      }}
    />
  )
}

export default Image
