import {
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DownloadIcon from "@mui/icons-material/Download";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

interface IDocument {
  id: string;
  title: string;
  document_status_id: number;
  document_id?: number;
  file_path?: string;
  created_at?: string;
}

interface RenderDocumentsProps {
  documents: IDocument[];
  anchorEl: any;
  handleClose: any;
  handleMenu: any;
  getIconByStatus: (statusId: number | undefined) => JSX.Element;
  onDocumentAction: any;
}

const RenderDocuments: React.FC<
  RenderDocumentsProps & { selectedYear: number; selectedMonth: number }
> = ({
  documents,
  anchorEl,
  handleClose,
  handleMenu,
  getIconByStatus,
  onDocumentAction,
  selectedYear,
  selectedMonth,
}) => {
  const filteredDocuments = documents.filter((document) => {
    if (!document.created_at) return false;

    const docDate = new Date(document.created_at);
    return (
      document.document_id == 9 &&
      docDate.getFullYear() === selectedYear &&
      docDate.getMonth() + 1 === selectedMonth
    );
  });

  if (filteredDocuments.length === 0) {
    return <ListItem>No se encontraron documentos por el momento</ListItem>;
  }

  return (
    <>
      {filteredDocuments.map(({ id, title, document_status_id }) => (
        <ListItem key={id} divider={true}>
          <ListItemIcon>{getIconByStatus(document_status_id)}</ListItemIcon>
          <ListItemText primary={title} />
          <IconButton onClick={handleMenu(id)}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl[id]}
            open={Boolean(anchorEl[id])}
            onClose={() => handleClose(id)}
          >
            <MenuItem onClick={() => onDocumentAction("download", id)}>
              <DownloadIcon />
            </MenuItem>
            <MenuItem onClick={() => onDocumentAction("approve", id)}>
              <CheckCircleOutlineIcon />
            </MenuItem>
            <MenuItem onClick={() => onDocumentAction("reject", id)}>
              <DoDisturbAltIcon />
            </MenuItem>
            <MenuItem onClick={() => onDocumentAction("delete", id)}>
              <DeleteForeverIcon />
            </MenuItem>
          </Menu>
        </ListItem>
      ))}
    </>
  );
};

export default RenderDocuments;
