import {
  alpha,
  Divider,
  Grid,
  Button,
  Menu,
  MenuItem,
  IconButton,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { useState } from "react";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const tabs = [
  { value: "info", label: "Información" },
  { value: "edit", label: "Editar" },
  { value: "new", label: "Nuevo" },
  { value: "photo", label: "Foto" },
];

interface AccountTabsProps {
  activeTab: string;
  onTabClick: (tab: string) => void;
  onTypeChange?: (type: "tutor" | "student") => void;
}

const AccountTabs: React.FC<AccountTabsProps> = ({
  activeTab,
  onTabClick,
  onTypeChange,
}) => {
  const [selectedType, setSelectedType] = useState<"student" | "tutor">(
    "tutor"
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const handleTypeClick = (type: "student" | "tutor") => {
    setSelectedType(type);
    if (onTypeChange) {
      onTypeChange(type);
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      container
      alignItems="center"
      sx={{
        mb: 2,
        justifyContent: "space-between",
      }}
    >
      {isMobile ? (
        <Grid item>
          <Button
            variant="outlined"
            onClick={handleMenuOpen}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {tabs.find((tab) => tab.value === activeTab)?.label || "Menú"}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {tabs.map((tab) => (
              <MenuItem
                key={tab.value}
                onClick={() => {
                  onTabClick(tab.value);
                  handleMenuClose();
                }}
              >
                {tab.label}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      ) : (
        <Grid item>
          <Grid container alignItems="center">
            {tabs.map((tab) => (
              <Button
                key={tab.value}
                onClick={() => onTabClick(tab.value)}
                sx={{
                  p: 1,
                  borderRadius: 0,
                  backgroundColor:
                    activeTab === tab.value
                      ? alpha("#e30613", 0.2)
                      : "transparent",
                }}
              >
                {tab.label}
              </Button>
            ))}
          </Grid>
        </Grid>
      )}

      <Grid item>
        <IconButton
          onClick={() =>
            handleTypeClick(selectedType === "tutor" ? "student" : "tutor")
          }
        >
          <SwitchAccountIcon />
        </IconButton>
      </Grid>
      {isMobile ? (
        <Grid item xs={12}>
          <Divider sx={{ mt: 2 }} />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}
    </Grid>
  );
};

export default AccountTabs;
