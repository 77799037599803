export const adminTabs = [
  {
    title: "Alumnos",
    description: {
      subTitle: null,
      image: null,
      steps: [
        {
          title: "Paso 1",
          text: "Podrás observar un listado completo de todos los alumnos. Para facilitar tu búsqueda, dispones de tres botones de filtro: uno para seleccionar categorías y dos para ordenar alfabéticamente la lista.",
          image: null,
        },
        {
          title: "Paso 2",
          text: "Al hacer clic en el botón de acción, se desplegarán todos los alumnos en la lista.",
          image: null,
        },
      ],
    },
  },
  {
    title: "Pagos",
    description: {
      subTitle: null,
      image: null,
      steps: [
        {
          title: "Paso 1",
          text: "En este apartado, tendrás acceso a una lista completa de los alumnos junto con sus respectivos pagos. Para optimizar tu búsqueda, cuentas con tres botones de filtro: uno para seleccionar categorías y dos para ordenar la lista alfabéticamente.",
          image: null,
        },
        {
          title: "Paso 2",
          text: "Al seleccionar el botón de acción, se mostrará la lista completa de alumnos.",
          image: null,
        },
      ],
    },
  },
  {
    title: "Notificaciones",
    description: {
      subTitle: null,
      image: null,
      steps: [
        {
          title: "Paso Único",
          text: "Este módulo te redirigirá a Mailchimp, proporcionándote las herramientas necesarias para enviar correos electrónicos de forma masiva.",
          image: null,
        },
      ],
    },
  },
  {
    title: "Admin",
    description: {
      subTitle: null,
      image: null,
      steps: [
        {
          title: "Paso Único",
          text: "Este apartado está diseñado para la creación de nuevos administradores dentro del sistema.",
          image: null,
        },
      ],
    },
  },
];
