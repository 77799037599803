const categoryMap = {
  1: "Baby",
  2: "Tiny-Mite",
  3: "Tiny-Tot",
  4: "Peewee",
  5: "Jr Midget",
  6: "Midget",
  7: "Jr Bantman",
};

export default categoryMap;
