import { AxiosResponse } from "axios";
import axiosInstance from "./axiosInstance";
import { User } from "../interfaces/user";

export const getAuthUser = async (): Promise<User> => {
  console.log("Fetching authenticated user data...");

  const response: AxiosResponse<{ data: User }> = await axiosInstance.get(
    "user/me"
  );

  console.log("Authenticated user data received:", response.data.data);

  return response.data.data;
};
