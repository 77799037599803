import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Grid, Button } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../../styles/theme";
import { Student } from "../../../interfaces/student";
import { Tutor } from "../../../interfaces/tutor";
import { useAccountFormEditHandlers } from "./AccountEditHandlers";
import StudentItem from "./StudentItem";
import TutorItem from "./TutorItem";
import { useGetUserStudentnTutor } from "../../../components/apiRequest/StudentAndTutorRequests";

interface AccountFormEditProps {
  userType: "student" | "tutor";
}

function AccountFormEdit({ userType }: AccountFormEditProps) {
  const navigate = useNavigate();
  const [student, setStudent] = useState<Student[] | null>(null);
  const [tutor, setTutor] = useState<Tutor[] | null>(null);

  const { students, tutors, isLoading, isError } = useGetUserStudentnTutor();

  //Comprueba si hay datos disponibles en students y tutors
  useEffect(() => {
    if (students && students.length > 0 && !student) {
      setStudent(students);
    }
    if (tutors && tutors.length > 0 && !tutor) {
      setTutor(tutors);
    }
  }, [students, tutors]);

  // Imported Handlers and Hooks
  const {
    handleBack,
    handleSaveStudent,
    handleSaveTutor,
    handleDeleteTutor,
    handleDeleteStudent,
    loadingTutorPut,
    loadingTutorDelete,
    loadingStudentPut,
    loadingStudentDelete,
  } = useAccountFormEditHandlers(setStudent, setTutor, student, tutor);

  const adaptedSetTutorData = (
    index: number
  ): React.Dispatch<React.SetStateAction<Tutor>> => {
    return (valueOrFn: React.SetStateAction<Tutor>) => {
      setTutor((prevTutors) => {
        const newTutors = [...(prevTutors ?? [])];

        let updatedTutor: Tutor;
        if (typeof valueOrFn === "function") {
          updatedTutor = (valueOrFn as Function)(newTutors[index]);
        } else {
          updatedTutor = valueOrFn;
        }

        newTutors[index] = { ...newTutors[index], ...updatedTutor };
        return newTutors;
      });
    };
  };

  const adaptedSetStudentData = (
    index: number
  ): React.Dispatch<React.SetStateAction<Student>> => {
    return (valueOrFn: React.SetStateAction<Student>) => {
      setStudent((prevStudents) => {
        const newStudents = [...(prevStudents ?? [])];

        let updatedStudent: Student;
        if (typeof valueOrFn === "function") {
          updatedStudent = (valueOrFn as Function)(newStudents[index]);
        } else {
          updatedStudent = valueOrFn;
        }

        newStudents[index] = { ...newStudents[index], ...updatedStudent };
        return newStudents;
      });
    };
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid item container spacing={3}>
        {userType === "tutor" &&
          Array.isArray(tutor) &&
          tutor.map((tutorData, index) => (
            <TutorItem
              tutorData={tutorData}
              loading={loadingTutorPut}
              onDelete={handleDeleteTutor}
              onSave={handleSaveTutor}
              setTutorData={adaptedSetTutorData(index)}
            />
          ))}
        {userType === "student" &&
          Array.isArray(student) &&
          student.map((studentData, index) => (
            <StudentItem
              studentData={studentData}
              loading={loadingStudentPut}
              onDelete={handleDeleteStudent}
              onSave={handleSaveStudent}
              setStudentData={adaptedSetStudentData(index)}
            />
          ))}
      </Grid>
    </ThemeProvider>
  );
}

export default AccountFormEdit;
