import React, { useState } from "react";
import { TextField, Button, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import axiosInstance from "../../api/axiosInstance";

const ChangePasswordForm = () => {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChangePassword = async () => {
    if (!password) {
      enqueueSnackbar("Por favor ingrese una nueva contraseña", {
        variant: "warning",
      });
      return;
    }

    try {
      setLoading(true);
      const response = await axiosInstance.put("user", { password });
      console.log(response.data);
      enqueueSnackbar("Contraseña actualizada exitosamente", {
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error al actualizar la contraseña", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <TextField
        label="Nueva Contraseña"
        variant="outlined"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button
        color="primary"
        variant="contained"
        onClick={handleChangePassword}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : "Cambiar Contraseña"}
      </Button>
    </div>
  );
};

export default ChangePasswordForm;
