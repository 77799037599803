import { useState } from "react";
import {
  Typography,
  Collapse,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import ContentLayout from "../../components/Layouts/ContentLayout/ContentLayout";
import { tabs } from "../../components/Text/tabsData";
import UserGuide from "./UserGuide";

function Main() {
  const [openTab, setOpenTab] = useState<number | null>(null);

  const handleClick = (index: number) => {
    setOpenTab(openTab === index ? null : index);
  };

  //const isMedium = useMediaQuery("(max-width:927px)");

  return (
    <ContentLayout
      title="Guía de usuario"
      paperStyles={{ height: "100%" }}
      showDivider
    >
      <Typography variant="body1" sx={{ mb: 3 }}>
        Bienvenido a la aplicación Rojos. A continuación podrás encontrar
        botones que indican que hace cada una de nuestras pestañas.
      </Typography>
      <List>
        <List>
          {tabs.map((tab, index) => (
            <div key={index}>
              <ListItem
                button
                onClick={() => handleClick(index)}
                sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
              >
                <ListItemText
                  primary={tab.title}
                  primaryTypographyProps={{
                    sx: {
                      fontWeight: "bold",
                    },
                  }}
                />
              </ListItem>
              <Collapse in={openTab === index} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem>
                    {tab.description.subTitle && (
                      <ListItemText secondary={tab.description.subTitle} />
                    )}
                  </ListItem>
                  {tab.description.steps.map((step, stepIndex) => (
                    <UserGuide
                      key={stepIndex}
                      title={step.title}
                      text={step.text}
                      image={step.image ?? ""}
                    />
                  ))}
                </List>
              </Collapse>
            </div>
          ))}
        </List>
      </List>
    </ContentLayout>
  );
}

export default Main;
