import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import UserContext from "../../context/UserContext";

function RequireAuth({
  children,
  adminRequired,
}: {
  children: JSX.Element;
  adminRequired?: boolean;
}) {
  const auth = useContext(AuthContext);
  const user = useContext(UserContext);
  const location = useLocation();

  if (!auth.isAuthenticated) {
    return <Navigate to="/iniciar" state={{ from: location }} replace />;
  }

  if (adminRequired && !user.user?.is_admin) {
    return <Navigate to="/iniciar" replace />; // redirigir a donde desees si el usuario no es admin
  }

  return children;
}

export default RequireAuth;
