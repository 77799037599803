import axios, { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../styles/theme";
import Copyright from "../../components/Copyright/Copyright";
import Image from "../../components/Image/Image";
import Logo from "../../assets/redsLogo.png";
import axiosInstance from "../../api/axiosInstance";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

function RecoverPassword() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false); // Estado para controlar si se ha enviado el correo

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Asegúrate de que event tenga el tipo React.ChangeEvent<HTMLInputElement>
    setEmail(event.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    // Asegúrate de que e tenga el tipo React.FormEvent<HTMLFormElement>
    e.preventDefault();
    try {
      // Realiza la petición para enviar el correo de recuperación
      await axios.post(
        `${process.env.REACT_APP_API_URL}/user/recover-password`,
        { email }
      );

      // Actualiza el estado para indicar que el correo se ha enviado exitosamente
      setEmailSent(true);

      // Muestra el mensaje de éxito
      enqueueSnackbar(
        "Correo de recuperación enviado. Por favor, revisa tu bandeja de entrada.",
        { variant: "success" }
      );
    } catch (err) {
      let message = "Error al intentar recuperar la contraseña.";
      // Manejo de errores con axios directamente
      if (axios.isAxiosError(err) && err.response) {
        const messageFromServer = err.response.data?.message;
        if (typeof messageFromServer === "string") {
          message = messageFromServer;
        }
      }
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const handleDismiss = () => {
    setEmailSent(false); // Restablece el estado después de cerrar el mensaje
    navigate("/login"); // Redirige a la página de login
  };
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[50]
              : theme.palette.grey[900],
        }}
      >
        <Container component="main" maxWidth="xs">
          <Paper
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              p: 3,
            }}
          >
            <Box sx={{ width: 1 / 2 }}>
              <Image src={Logo} alt="Reds Logo" />
            </Box>
            {/* Mostrar mensaje de éxito si el correo se ha enviado exitosamente */}
            {emailSent ? (
              <>
                <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
                  Se ha enviado una contraseña de recuperación a tu correo.
                </Typography>
                <CheckCircleOutlineIcon
                  sx={{
                    fontSize: 80,
                    color: theme.palette.success.main,
                    mt: 2,
                    mb: 2,
                  }}
                />
              </>
            ) : (
              <>
                <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
                  Recuperar Contraseña
                </Typography>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <Typography>
                    Introduzca el correo de su cuenta para poder enviarle una
                    contraseña temporal
                  </Typography>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Correo Electrónico"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Enviar Contraseña Temporal
                  </Button>
                </Box>
              </>
            )}
          </Paper>
          <Copyright mt={8} mb={4} />
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default RecoverPassword;
