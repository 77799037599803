import { Grid, Paper } from "@mui/material";
import AccountTabs from "../TabsSelector/AccountTabs";
import ContentLayout from "../../ContentLayout/ContentLayout";

interface AccountContainerProps {
  activeTab: string;
  onTabClick: (tab: string) => void;
  onTypeChange?: (type: "tutor" | "student") => void;
  tabs: { label: string; value: string }[];
  children: React.ReactNode;
}

const AccountContainer: React.FC<AccountContainerProps> = ({
  activeTab,
  onTabClick,
  onTypeChange,
  children,
}) => {
  return (
    <ContentLayout paperStyles={{ height: "100%" }}>
      <Grid>
        <AccountTabs
          activeTab={activeTab}
          onTabClick={onTabClick}
          onTypeChange={onTypeChange}
        />
        {children}
      </Grid>
    </ContentLayout>
  );
};

export default AccountContainer;
