import { Tutor } from "../../../interfaces/tutor";
import { Student } from "../../../interfaces/student";
import { useNavigate } from "react-router";
import {
  useTutorPut,
  useTutorDelete,
  useStudentPut,
  useStudentDelete,
} from "../../../components/apiRequest/StudentAndTutorRequests";

export function useAccountFormEditHandlers(
  setStudent: React.Dispatch<React.SetStateAction<Student[] | null>>,
  setTutor: React.Dispatch<React.SetStateAction<Tutor[] | null>>,
  student: Student[] | null,
  tutor: Tutor[] | null
) {
  const navigate = useNavigate();

  // API request Hooks

  const { loading: loadingTutorPut, put: tutorPut } = useTutorPut(
    tutor,
    setTutor
  );
  const { loading: loadingTutorDelete, remove: tutorDelete } =
    useTutorDelete(setTutor);
  const { loading: loadingStudentPut, put: studentPut } = useStudentPut(
    student,
    setStudent
  );
  const { loading: loadingStudentDelete, remove: studentDelete } =
    useStudentDelete(setStudent);

  // Buttons' Actions

  const handleBack = () => {
    navigate("/cuenta");
  };

  const handleSaveStudent = (studentId: string) => {
    if (studentId) {
      studentPut(studentId);
    } else {
      console.warn("Student ID is undefined");
    }
  };

  const handleSaveTutor = (tutorId: string) => {
    if (tutorId) {
      tutorPut(tutorId);
    } else {
      console.warn("Tutor ID is undefined");
    }
  };

  const handleDeleteTutor = (tutorId: string) => {
    if (tutorId) {
      tutorDelete(tutorId);
    } else {
      console.warn("Tutor ID is undefined");
    }
  };

  const handleDeleteStudent = (studentId: string) => {
    if (studentId) {
      studentDelete(studentId);
    } else {
      console.warn("Student ID is undefined");
    }
  };

  return {
    handleBack,
    handleSaveStudent,
    handleSaveTutor,
    handleDeleteTutor,
    handleDeleteStudent,
    loadingTutorPut,
    loadingTutorDelete,
    loadingStudentPut,
    loadingStudentDelete,
  };
}
