import { Box, ListItemButton, ListItemText, ListItemIcon } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PaymentIcon from "@mui/icons-material/Payment";
import DescriptionIcon from "@mui/icons-material/Description";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import UserContext from "../../context/UserContext";

function NavigationList({ onClose }: { onClose?: () => void }) {
  const userContext = useContext(UserContext);
  const { user } = userContext;

  const handleListItemClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (onClose) {
      onClose();
    }
  };

  const adminMenuItems = [
    { text: "Inicio", icon: <HomeIcon />, link: "/" },
    { text: "Alumnos", icon: <PersonIcon />, link: "/alumnos" },
    { text: "Pagos", icon: <AccountBalanceWalletIcon />, link: "/pagos-admin" },
    { text: "Notificaciones", icon: <NotificationsIcon /> },
    { text: "Admin", icon: <PersonAddIcon />, link: "admin-registro" },
  ];

  const userMenuItems = [
    { text: "Inicio", icon: <HomeIcon />, link: "/" },
    { text: "Cuenta", icon: <AccountBoxIcon /> },
    { text: "Pagos", icon: <PaymentIcon /> },
    { text: "Documentos", icon: <DescriptionIcon /> },
    { text: "Ajustes", icon: <SettingsIcon /> },
  ];

  const MenuItems = user?.is_admin ? adminMenuItems : userMenuItems;

  return (
    <Box>
      {MenuItems.map((item) => (
        <ListItemButton
          key={item.text}
          component={Link}
          to={item.link || `/${item.text.toLowerCase().replace(/ /g, "-")}`}
          onClick={handleListItemClick}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItemButton>
      ))}
    </Box>
  );
}

export default NavigationList;
