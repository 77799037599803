import { Grid, Paper, Typography, Divider, Button } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../styles/theme";

function Notifications() {
  return (
    <ThemeProvider theme={theme}>
      <Grid item xs={12} sx={{ height: "100%" }}>
        <Paper sx={{ p: 4, height: "100%" }}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Notificaciones
            </Typography>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              href="https://us12.admin.mailchimp.com"
              target="_blank"
            >
              Ir a Mailchimp
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </ThemeProvider>
  );
}

export default Notifications;
