import axios from "axios";
import { useState } from "react";
import {
  Box,
  Button,
  Container,
  Link,
  Paper,
  Typography,
  Stepper,
  StepLabel,
  Step,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Image from "../../components/Image/Image";
import Logo from "../../assets/redsLogo.png";
import Copyright from "../../components/Copyright/Copyright";
import StudentForm from "./StudentForm";
import TutorForm from "./TutorForm";
import TutorFormTwo from "./TutorFormTwo";
import Schedule from "./Schedule";
import { useSnackbar } from "notistack";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../styles/theme";

// Form Steps
const steps = ["Jugador", "Primer Tutor", "Segundo Tutor", "Horarios"];

function getStepContent(step: number, leadData: any, setLeadData: any) {
  switch (step) {
    case 0:
      return <StudentForm leadData={leadData} setLeadData={setLeadData} />;
    case 1:
      return <TutorForm leadData={leadData} setLeadData={setLeadData} />;
    case 2:
      return <TutorFormTwo leadData={leadData} setLeadData={setLeadData} />;
    case 3:
      return <Schedule leadData={leadData} setLeadData={setLeadData} />;
    default:
      throw new Error("Unknown step");
  }
}

function calculateAge(birthdate: string) {
  const diff = Date.now() - new Date(birthdate).getTime();
  const ageDate = new Date(diff);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

function Leads() {
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);

  const redLeads = process.env.REACT_APP_API_LEADS;
  console.log(redLeads);

  const [leadData, setLeadData] = useState({
    first_name: "",
    last_name: "",
    birthdate: null,
    tutors: [
      {
        id: 1,
        first_name: "",
        last_name: "",
        company: "",
        company_role: "",
        email: "",
        phone: "",
      },
      {
        id: 2,
        first_name: "",
        last_name: "",
        company: "",
        company_role: "",
        email: "",
        phone: "",
      },
    ],
    demo_class: "",
  });

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSubmit = async () => {
    try {
      const { first_name, last_name, birthdate } = leadData;
      if (!first_name || !last_name || !birthdate) {
        enqueueSnackbar("Por favor llene todos los datos", {
          variant: "error",
        });
        return;
      }

      const age = calculateAge(birthdate);
      if (isNaN(age) || age < 5 || age > 18) {
        enqueueSnackbar("La edad debe ser entre 5 y 18 años", {
          variant: "error",
        });
        return;
      }

      if (activeStep === 1) {
        const { first_name, last_name, company, company_role, email, phone } =
          leadData.tutors[0];

        if (
          !first_name ||
          !last_name ||
          !company ||
          !company_role ||
          !email ||
          !phone
        ) {
          enqueueSnackbar("Por favor llene todos los datos", {
            variant: "error",
          });
          return;
        }
      }

      if (activeStep === 3) {
        const { demo_class } = leadData;

        if (!demo_class) {
          enqueueSnackbar("Por favor seleccione un horario", {
            variant: "error",
          });
          return;
        }

        if (redLeads) {
          const response = await axios.post(redLeads, leadData);
          console.log(response.data);
        }
      }

      handleNext();
    } catch (err) {
      if (err instanceof Error) {
        enqueueSnackbar(err.message, { variant: "error" });
      } else {
        enqueueSnackbar("Unknown Error", { variant: "error" });
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[50]
              : theme.palette.grey[900],
        }}
      >
        <Container component="main" maxWidth="sm">
          <Paper
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              p: 3,
            }}
          >
            <Box sx={{ width: 1 / 2 }}>
              <Image src={Logo} alt="Reds Logo" />
            </Box>
            <Box sx={{ mt: 1 }}>
              <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 3 }}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length ? (
                <>
                  <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
                    Gracias por reservar su clase muestra
                  </Typography>
                  <CheckCircleOutlineIcon
                    sx={{
                      fontSize: 80,
                      color: theme.palette.success.main,
                      mt: 2,
                      mb: 2,
                    }}
                  />
                  <Typography variant="subtitle1">
                    La confirmación de su reserva será enviada a su correo.
                  </Typography>
                </>
              ) : (
                <>
                  {getStepContent(activeStep, leadData, setLeadData)}
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                        Atrás
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      sx={{ mt: 3, ml: 1 }}
                    >
                      {activeStep === steps.length - 1
                        ? "Confirmar"
                        : "Siguiente"}
                    </Button>
                  </Box>
                </>
              )}
            </Box>
            <Typography variant="body2" color="text.secondary" mt={2}>
              Al registrate con nosotros estás de acuerdo con nuestros{" "}
              <Link>términos de uso</Link> y <Link>aviso de privacidad</Link>
            </Typography>
          </Paper>
          <Copyright mt={8} mb={4} />
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Leads;
