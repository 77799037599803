import { Grid } from "@mui/material";
import TutorTabsSelector from "../TabsSelector/AdminTutorTabs";
import { Tutor } from "../../../../interfaces/tutor";
import ContentLayout from "../../ContentLayout/ContentLayout";

interface AdminTutorsContainerProps {
  activeTab: string;
  onTabClick: (tab: string) => void;
  tabs: { label: string; value: string }[];
  children: React.ReactNode;
  studentId: string;
  tutors: Tutor[];
  selectedTutorIndex: number;
  handleTutorSelection: (index: number) => void;
}

const AdminTutorsContainer: React.FC<AdminTutorsContainerProps> = ({
  activeTab,
  onTabClick,
  tabs,
  children,
  studentId,
  tutors,
  selectedTutorIndex,
  handleTutorSelection,
}) => {
  return (
    <ContentLayout paperStyles={{ height: "100%" }}>
      <Grid>
        <TutorTabsSelector
          activeTab={activeTab}
          onTabClick={onTabClick}
          tabs={tabs}
          tutors={tutors}
          handleTutorSelection={handleTutorSelection}
        />
        {children}
      </Grid>
    </ContentLayout>
  );
};

export default AdminTutorsContainer;
