import { Grid, Paper, Typography, Divider } from "@mui/material";
import axiosInstance from "../../api/axiosInstance";
import { useContext, useEffect, useState } from "react";
import UserContext from "../../context/UserContext";
import StudentSelect from "./StudentSelect";
import ContentLayout from "../../components/Layouts/ContentLayout/ContentLayout";
import CuentaPagos from "./CuentaPagos";

// Componente de Payments
function Payments() {
  const userContext = useContext(UserContext);
  const { user } = userContext;
  console.log("User", user);

  return (
    <ContentLayout paperStyles={{ height: "100%" }} title="Pagos" showDivider>
      <Grid item xs={12}>
        <CuentaPagos />
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ mb: 1, mt: 4 }} />
      </Grid>
      <Grid item xs={12}>
        <StudentSelect />
      </Grid>
    </ContentLayout>
  );
}

export default Payments;
