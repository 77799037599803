import {
  alpha,
  Box,
  Button,
  Menu,
  MenuItem,
  IconButton,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Tutor } from "../../../../interfaces/tutor";
import theme from "../../../../styles/theme";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";

interface AdminTutorTabsProps {
  activeTab: string;
  onTabClick: (tab: string) => void;
  tabs: { label: string; value: string }[];
  tutors?: Tutor[];
  handleTutorSelection?: (index: number) => void;
}

const AdminTutorTabs: React.FC<AdminTutorTabsProps> = ({
  activeTab,
  onTabClick,
  tabs,
  tutors,
  handleTutorSelection,
}) => {
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [tabsAnchorEl, setTabsAnchorEl] = useState<null | HTMLElement>(null);
  const [tutorAnchorEl, setTutorAnchorEl] = useState<null | HTMLElement>(null);

  const handleTabsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setTabsAnchorEl(event.currentTarget);
  };

  const handleTabsMenuClose = () => {
    setTabsAnchorEl(null);
  };

  const handleTutorMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setTutorAnchorEl(event.currentTarget);
  };

  const handleTutorMenuClose = () => {
    setTutorAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        mb: 2,
        borderBottom: "1px solid #d3d3d3",
        justifyContent: "space-between",
      }}
    >
      {isSm ? (
        <>
          <Button onClick={handleTabsMenuClick}>
            {tabs.find((tab) => tab.value === activeTab)?.label || "Menú"}
          </Button>
          <Menu
            anchorEl={tabsAnchorEl}
            open={Boolean(tabsAnchorEl)}
            onClose={handleTabsMenuClose}
          >
            {tabs.map((tab) => (
              <MenuItem
                key={tab.value}
                onClick={() => {
                  onTabClick(tab.value);
                  handleTabsMenuClose();
                }}
                selected={activeTab === tab.value}
              >
                {tab.label}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {tabs.map((tab) => (
            <Button
              key={tab.value}
              onClick={() => onTabClick(tab.value)}
              sx={{
                p: 1,
                borderRadius: 0,
                backgroundColor:
                  activeTab === tab.value
                    ? alpha("#e30613", 0.2)
                    : "transparent",
              }}
            >
              {tab.label}
            </Button>
          ))}
        </Box>
      )}

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton onClick={handleTutorMenuClick}>
          <SwitchAccountIcon />
        </IconButton>
        <Menu
          anchorEl={tutorAnchorEl}
          open={Boolean(tutorAnchorEl)}
          onClose={handleTutorMenuClose}
        >
          {tutors?.map((tutor, index) => (
            <MenuItem
              value={index}
              key={tutor.id}
              onClick={() => {
                handleTutorSelection!(index);
                handleTutorMenuClose();
              }}
            >
              {tutor.first_name} {tutor.last_name}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Box>
  );
};

export default AdminTutorTabs;
