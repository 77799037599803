import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import styled from "@emotion/styled";
import { subYears } from "date-fns";
import { TextField } from "@mui/material";

const FullWidthDatePicker = styled(DatePicker)`
  width: 100%;
`;

interface ICustomDatePickerProps {
  label: string;
  value: string | null;
  onChange: (date: string) => void;
  disabled?: boolean;
}

const today = new Date();
const maxBirthDate = subYears(today, 3);
const minBirthDate = subYears(today, 100);

const CustomDatePicker: React.FC<ICustomDatePickerProps> = ({
  label,
  value,
  onChange,
  disabled,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FullWidthDatePicker
        label={label}
        value={value ? new Date(value) : null}
        onChange={(date) => {
          if (date instanceof Date) {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, "0"); // +1 porque getMonth() devuelve 0-11
            const day = date.getDate().toString().padStart(2, "0");

            const dateString = `${year}-${month}-${day}`;
            onChange(dateString);
          }
        }}
        disabled={disabled}
        minDate={minBirthDate}
        maxDate={maxBirthDate}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
